/* eslint-disable @typescript-eslint/naming-convention */
import { EventEmitter, Injectable } from '@angular/core';
import moment from 'moment';
import { LoadAllocation, LoadOrderModel, MasterRoute, VehicleOverride, VehicleTemplate } from '@ov-suite/models-warehouse';
import { PageReturn } from '@ov-suite/ov-metadata';
import { VehicleClass } from '@ov-suite/models-admin';
import { OvAutoService, OvAutoServiceMultipleMutationParams } from '@ov-suite/services';
import { getCreate } from '@ov-suite/graphql-helpers';
import * as _ from 'lodash';
import { DateRange } from '@ov-suite/ui';
import { DomainService } from '@ov-suite/helpers-angular';
import { ListCombo, SingleCombo, Slot, VehicleAllocation } from './load-allocation.interface';
import { commitLoadAllocationGql, unCommitLoadAllocationGql } from './load-allocation.grahpql';
import { OrderAllocation, SplitParam } from './classes/order-allocation.class';

/**
 * This Service is used for Fetching, Manipulating and Saving Data.
 *
 * This should be the only service on load-allocation with ovAutoService. Please be sure to manage observables correctly
 */

interface VehicleFilter {
  searchTerm: string;
  vehicleClassFilter: VehicleClass;
  activeFilter: LoadFilter;
}

interface OrderAllocationFilter {
  customerSearchTerm: string;
  masterRoute: MasterRoute;
}

interface DirtyLoad {
  updates: Record<number, VehicleAllocation>;
  creates: VehicleAllocation[];
}

export enum LoadFilter {
  All,
  Planning,
  Confirmed,
  Processed,
}

interface SortableColumn {
  name: string;
  getSortValue: (orderAllocation: OrderAllocation) => unknown;
}

@Injectable()
export class LoadAllocationDataService {
  date: SingleCombo<Date> = {
    value: new Date(),
    observable: new EventEmitter<Date>(),
  };

  orderAllocations: ListCombo<OrderAllocation> = {
    value: [],
    map: {},
    all: [],
    observable: new EventEmitter<OrderAllocation[]>(),
  };

  orderAllocationsFilter: OrderAllocationFilter = {
    customerSearchTerm: null,
    masterRoute: null,
  };

  customerIdsByMasterRoute: number[] = [];

  vehicleAllocation: ListCombo<VehicleAllocation> = {
    value: [],
    map: {},
    all: [],
    observable: new EventEmitter<VehicleAllocation[]>(),
  };

  vehicleIdToAllocationMap: Record<number, VehicleAllocation> = {};

  vehicleTemplates: ListCombo<VehicleTemplate> = {
    value: [],
    observable: new EventEmitter<VehicleTemplate[]>(),
  };

  vehicleClasses: ListCombo<VehicleClass> = {
    value: [],
    observable: new EventEmitter<VehicleClass[]>(),
  };

  vehicleFilter: VehicleFilter = {
    searchTerm: null,
    vehicleClassFilter: null,
    activeFilter: LoadFilter.Planning,
  };

  dirty: DirtyLoad = {
    updates: {},
    creates: [],
  };

  allocatedOrderIds: Record<number, boolean> = {};

  masterRoutes: MasterRoute[] = [];

  _orderDateRange: DateRange = {
    type: 'date-range',
    to: new Date(),
  };

  set orderDateRange(input: DateRange) {
    this._orderDateRange = input;
  }

  get orderDateRange(): DateRange {
    return this._orderDateRange;
  }

  orderAllocationColumns: SortableColumn[] = [
    { name: 'Order ID', getSortValue: this.getId },
    { name: 'Customer ID', getSortValue: this.getCustomerId },
    // { name: 'Region', getSortValue: this.getAddress },
    { name: 'Weight', getSortValue: this.getWeight },
    { name: 'Volume', getSortValue: this.getVolume },
  ];

  sortingData: { column: string; direction: 'ASC' | 'DESC' } = {
    column: 'id',
    direction: 'ASC',
  };

  constructor(private readonly ovAutoService: OvAutoService, private readonly domainService: DomainService) {}

  public startUp() {}

  debounceSave = _.debounce(() => this.saveAll(), 1000, { leading: false, trailing: true, maxWait: 3000 });

  debounceFetch: Function = _.debounce(() => this.fetchData(), 200, { leading: false, trailing: true, maxWait: 3000 });

  async saveAll(): Promise<void> {
    this.dirty.creates = this.vehicleAllocation.all.filter(i => !i.load.id);
    const updatePrefix = 'update_';
    const createPrefix = 'create_';

    const domainPath = this.domainService.getCurrentDomain();

    // ¯\_(ツ)_/¯
    const params: OvAutoServiceMultipleMutationParams = {};

    // function getCreateForLoad(allocation: VehicleAllocation) {
    //   return {
    //     id: allocation.load.id,
    //     vehicle: allocation.load.vehicle,
    //     date: allocation.load.date,
    //     commit: allocation.load.commit,
    //     commitDate: allocation.load.commitDate,
    //     loadOrders: allocation.load.loadOrders.map(l => ({ id: l.id })),
    //   };
    // }

    Object.values(this.dirty.updates).forEach(u => {
      const item = getCreate(u.load);
      item['domainIds'] = domainPath.path;
      params[`${updatePrefix}${u.load.id}`] = {
        type: 'update',
        entity: LoadAllocation,
        item,
        keys: ['id'],
      };
    });

    this.dirty.creates.forEach((c, i) => {
      const item = getCreate(c.load);
      item['domainIds'] = domainPath.path;
      params[`${createPrefix}${i}`] = {
        type: 'create',
        entity: LoadAllocation,
        item,
        keys: ['id'],
      };
    });

    if (!Object.values(params).length) {
      return;
    }

    this.ovAutoService.multipleMutation(params).then(response => {
      const indexesCleared: number[] = [];
      Object.entries(response).forEach(([key, value]) => {
        if (key.startsWith(createPrefix)) {
          const index = Number(key.slice(createPrefix.length));
          indexesCleared.push(index);
          this.dirty.creates[index].load.id = Number(value.id);
        } else if (key.startsWith(updatePrefix)) {
          const index = Number(key.slice(updatePrefix.length));
          delete this.dirty.updates[index];
        }
      });
      this.dirty.creates = this.dirty.creates.filter((c, i) => !indexesCleared.includes(i));
    });
  }

  async saveLoadOrder(...loadOrders: LoadOrderModel[]): Promise<LoadOrderModel[]> {
    const updatePrefix = 'update_';
    const createPrefix = 'create_';
    // ¯\_(ツ)_/¯
    const params: OvAutoServiceMultipleMutationParams = {};
    loadOrders.forEach((c, i) => {
      const item = {
        id: undefined,
        orderId: c.order.id,
        name: c.name,
        loadOrderItems: c.loadOrderItems.map(k => getCreate(k)),
      };

      if (c.id) {
        item.id = c.id;
        params[`${updatePrefix}${c.id}`] = {
          type: 'update',
          entity: LoadOrderModel,
          item,
          keys: ['id'],
        };
      } else {
        params[`${createPrefix}${i}`] = {
          type: 'create',
          entity: LoadOrderModel,
          item,
          keys: ['id'],
        };
      }
    });

    return this.ovAutoService.multipleMutation(params).then(response => {
      Object.values(response).forEach((value, index) => {
        loadOrders[index].id = Number(value.id);
      });
      return loadOrders;
    });
  }

  saveLoad(load: LoadAllocation) {
    this.ovAutoService.apollo
      .use('warehouselink')
      .mutate({
        mutation: load.commitDate ? commitLoadAllocationGql() : unCommitLoadAllocationGql(),
        fetchPolicy: 'no-cache',
        variables: {
          load: getCreate(load),
        },
      })
      .toPromise()
      .catch(error => {
        throw error;
      });
  }

  async fetchData(): Promise<void> {
    const date = moment(this.date.value).format('yyyy-MM-DD');

    const orderVariables: { dateFrom?: string; dateTo: string; customerSearch?: string; masterRouteId?: number } = {
      dateTo: moment(this.date.value).endOf('day').toISOString(),
    };
    if (this.orderDateRange?.to) {
      orderVariables.dateTo = moment(this.orderDateRange.to).endOf('day').toISOString();
      if (this.orderDateRange.from) {
        orderVariables.dateFrom = moment(this.orderDateRange.from).startOf('day').toISOString();
      }
    }
    if (this.orderAllocationsFilter.customerSearchTerm) {
      orderVariables.customerSearch = this.orderAllocationsFilter.customerSearchTerm;
    }

    if (this.orderAllocationsFilter.masterRoute) {
      orderVariables.masterRouteId = this.orderAllocationsFilter.masterRoute.id;
    }

    this.ovAutoService
      .multipleFetch({
        orders: {
          entity: LoadOrderModel,
          type: 'custom',
          paramsName: 'LoadOrderQueryInput',
          name: 'listLoadOrders',
          variables: orderVariables,
          keys: [
            'id',
            'name',
            'loadOrderItems.id',
            'loadOrderItems.quantity',
            'loadOrderItems.orderItem.id',
            'loadOrderItems.orderItem.quantity',
            'loadOrderItems.orderItem.productSku.id',
            'loadOrderItems.orderItem.productSku.sku',
            'loadOrderItems.orderItem.productSku.length',
            'loadOrderItems.orderItem.productSku.width',
            'loadOrderItems.orderItem.productSku.height',
            'loadOrderItems.orderItem.productSku.weight',
            'order.id',
            'order.location',
            'order.priority',
            'order.customer.id',
            'order.customer.name',
            'order.customer.customerCode',
            'order.customer.description',
            'order.customer.map',
            'order.orderItems.id',
            'order.orderItems.quantity',
            'order.orderItems.productSku.id',
            'order.orderItems.productSku.sku',
            'order.orderItems.productSku.length',
            'order.orderItems.productSku.width',
            'order.orderItems.productSku.height',
            'order.orderItems.productSku.weight',
            'order.fulfilmentDate',
            'order.orderDate',
            'order.orderCode',
            'order.dueDate',
          ],
          mapToClass: true,
        },
        vehicles: {
          entity: VehicleOverride,
          type: 'list',
          keys: [
            'id',
            'name',
            'registration',
            'class.id',
            'class.name',
            'class.weightLoadAllowed',
            'class.length',
            'class.width',
            'class.height',
            'class.volumeRedPercentage',
            'class.volumeOrangePercentage',
            'class.weightRedPercentage',
            'class.weightOrangePercentage',
            'resource.startTime',
            'resource.endTime',
          ],
        },
        loads: {
          entity: LoadAllocation,
          type: 'list',
          keys: [
            'id',
            'date',
            'commit',
            'commitDate',
            'releaseDate',
            'vehicle.id',
            'externalVehicle.id',
            'externalVehicle.vehicleClass',
            'externalVehicle.model',
            'externalVehicle.make',
            'externalVehicle.weightLimit',
            'externalVehicle.width',
            'externalVehicle.length',
            'externalVehicle.height',
            'externalVehicle.startTime',
            'externalVehicle.endTime',
            'externalVehicle.registration',
            'loadOrders.id',
            'loadOrders.name',
            'loadOrders.loadOrderItems.id',
            'loadOrders.loadOrderItems.quantity',
            'loadOrders.loadOrderItems.orderItem.id',
            'loadOrders.loadOrderItems.orderItem.quantity',
            'loadOrders.loadOrderItems.orderItem.productSku.id',
            'loadOrders.loadOrderItems.orderItem.productSku.sku',
            'loadOrders.loadOrderItems.orderItem.productSku.length',
            'loadOrders.loadOrderItems.orderItem.productSku.width',
            'loadOrders.loadOrderItems.orderItem.productSku.height',
            'loadOrders.loadOrderItems.orderItem.productSku.weight',
            'loadOrders.order.id',
            'loadOrders.order.location',
            'loadOrders.order.priority',
            'loadOrders.order.customer.id',
            'loadOrders.order.customer.name',
            'loadOrders.order.customer.customerCode',
            'loadOrders.order.customer.description',
            'loadOrders.order.customer.map',
            'loadOrders.order.orderItems.id',
            'loadOrders.order.orderItems.quantity',
            'loadOrders.order.orderItems.productSku.id',
            'loadOrders.order.orderItems.productSku.sku',
            'loadOrders.order.orderItems.productSku.length',
            'loadOrders.order.orderItems.productSku.width',
            'loadOrders.order.orderItems.productSku.height',
            'loadOrders.order.orderItems.productSku.weight',
            'loadOrders.order.fulfilmentDate',
            'loadOrders.order.orderDate',
            'loadOrders.order.orderCode',
            'loadOrders.order.dueDate',
            'vehicleTemplate',
            'vehicleTemplate.vehicleLines',
          ],
          query: {
            date: [date],
          },
        },
        templates: {
          entity: VehicleTemplate,
          type: 'list',
          keys: ['id', 'name', 'vehicleLines.id', 'vehicleLines.vehicle.id', 'vehicleLines.startTime', 'vehicleLines.endTime'],
        },
        masterRoutes: {
          entity: MasterRoute,
          type: 'list',
          keys: ['id', 'name', 'customerIds'],
        },
      })
      .then(response => {
        const templates = (response.templates as PageReturn<VehicleTemplate>).data;
        const loads = (response.loads as PageReturn<LoadAllocation>).data;
        const orders = response.orders as LoadOrderModel[];
        const vehicles = (response.vehicles as PageReturn<VehicleOverride>).data.filter(v => !!v.resource);
        const masterRoutes = (response.masterRoutes as PageReturn<MasterRoute>).data;

        this.setTemplates(templates);
        this.createOrderAllocations(orders, loads);
        this.createVehicleAllocations(vehicles, loads, date);
        this.sortLoadAllocations();
        this.filterVehicles();
        this.setMasterRoutes(masterRoutes);
      });
  }

  setDate(date: Date): void {
    if (date !== this.date.value) {
      this.date.value = date;
      this.date.observable.emit(date);
      this.debounceFetch();
    }
  }

  setTemplates(templates: VehicleTemplate[]): void {
    this.vehicleTemplates.value = templates;
    this.vehicleTemplates.observable.emit(templates);
  }

  createOrderAllocations(orders: LoadOrderModel[], loads: LoadAllocation[]): void {
    this.allocatedOrderIds = {};
    loads.forEach(l => {
      l.loadOrders.forEach(o => {
        this.allocatedOrderIds[o.id] = true;
      });
    });

    this.orderAllocations.map = {};
    orders.forEach(o => {
      this.orderAllocations.map[o.id] = OrderAllocation.fromLoadOrder(o);
    });

    this.orderAllocations.all = Object.values(this.orderAllocations.map);
    this.setOrderAllocations(this.orderAllocations.all);
  }

  createVehicleAllocations(vehicles: VehicleOverride[], loads: LoadAllocation[], date: string): void {
    const allocated: Record<number, LoadAllocation> = {};
    // const allocatedExternal: Record<number, LoadAllocation> = {};
    const externalAllocations: LoadAllocation[] = [];

    loads.forEach(p => {
      if (p.isExternalVehicle()) {
        console.log('has external');
        externalAllocations.push(p);
      } else {
        allocated[p.getVehicle().id] = p;
      }
    });
    const loadAllocations = vehicles.map(v => {
      if (allocated[v.id]) {
        const allocation = allocated[v.id];
        allocation.setVehicle(v);
        return allocation;
      }
      return LoadAllocation.fromVehicle(v, date);
    });

    // Object.values(allocatedExternal).map(ev => {
    //   return LoadAllocation.fromExternalVehicle(ev.externalVehicle, date);
    // });

    loadAllocations.push(...externalAllocations);

    this.vehicleAllocation.all = loadAllocations.map(l => VehicleAllocation.fromLoadAllocation(l));
    const vehicleClassesMap: Record<number, VehicleClass> = {};

    this.vehicleAllocation.all.forEach(allocation => {
      this.vehicleIdToAllocationMap[allocation.load.getVehicle().id] = allocation;
      vehicleClassesMap[allocation.load.getVehicle().class.id] = allocation.load.getVehicle().class;
    });

    this.vehicleClasses.value = Object.values(vehicleClassesMap);
    this.vehicleClasses.observable.emit(this.vehicleClasses.value);

    this.setVehicles(this.vehicleAllocation.all);
  }

  setVehicles(vehicleAllocations: VehicleAllocation[]): void {
    this.vehicleAllocation.value = vehicleAllocations;
    this.vehicleAllocation.observable.emit(vehicleAllocations);
  }

  setOrderAllocations(orderAllocations: OrderAllocation[]): void {
    this.orderAllocations.value = orderAllocations.filter(o => !this.allocatedOrderIds[o.loadOrder.id]);
    this.orderAllocations.observable.emit(this.orderAllocations.value);
  }

  sortLoadAllocations() {
    this.vehicleAllocation.all = this.vehicleAllocation.all.sort((a, b) => {
      if (a.load.isExternalVehicle() && !b.load.isExternalVehicle()) {
        return 1;
      }
      if (a.load.isExternalVehicle() && b.load.isExternalVehicle()) {
        return 1;
      }
      return -1;
    });
  }

  async updateLoadsFromTemplate(template: VehicleTemplate): Promise<void> {
    this.vehicleAllocation.value.forEach(vehicle => {
      const id = vehicle.load.vehicle?.id;
      if (id) {
        const vehicleTemplate = template.vehicleLines.find(v => v.vehicle?.id === id);
        if (vehicleTemplate) {
          let [slot] = vehicle.slots;
          slot ??= new Slot();
          slot.startTime = vehicleTemplate.startTime;
          slot.endTime = vehicleTemplate.endTime;
          if (!slot.id) {
            vehicle.slots.push(slot);
          }
        } else if (!vehicle.load.loadOrders?.length) {
          vehicle.slots = [];
        }
        vehicle.load.vehicleTemplate = template;
        this.makeDirty(vehicle);
      }
    });
    this.vehicleAllocation.observable.emit(this.vehicleAllocation.value);
    await this.saveAll();
  }

  async resetTemplate(): Promise<void> {
    this.vehicleAllocation.value.forEach(vehicle => {
      let [slot] = vehicle.slots;
      slot ??= new Slot();
      slot.startTime = vehicle.load.getVehicle().resource.startTime;
      slot.endTime = vehicle.load.getVehicle().resource.endTime;
      if (!slot.id) {
        vehicle.slots.push(slot);
      }
      vehicle.load.vehicleTemplate = null;
      this.makeDirty(vehicle);
    });
    this.vehicleAllocation.observable.emit(this.vehicleAllocation.value);
    await this.saveAll();
  }

  filterVehicles(term?: string): void {
    if (term !== undefined) {
      this.vehicleFilter.searchTerm = term ?? null;
    }
    const searchTerm = this.vehicleFilter.searchTerm?.toLowerCase();
    const vehicles = this.vehicleAllocation.all.filter(allocation => {
      const vehicle = allocation.load.getVehicle();
      let planningFilter: boolean;
      let searchFilter = true;
      let classFilter = true;

      if (this.vehicleFilter.vehicleClassFilter) {
        classFilter = this.vehicleFilter.vehicleClassFilter.id === allocation.load.getVehicle().class.id;
      }

      if (searchTerm) {
        searchFilter =
          vehicle.name.toLowerCase().includes(searchTerm) ||
          vehicle.registration.toLowerCase().includes(searchTerm) ||
          vehicle.class.name.toLowerCase().includes(searchTerm);
      }

      switch (this.vehicleFilter.activeFilter) {
        case LoadFilter.All:
          planningFilter = true;
          break;
        case LoadFilter.Confirmed:
          planningFilter = !!allocation.load.commit;
          break;
        case LoadFilter.Planning:
          planningFilter = !allocation.load.commit;
          break;
        case LoadFilter.Processed:
          // Todo: Put in logic for processed Loads
          break;
        default:
      }

      return classFilter && planningFilter && searchFilter;
    });
    this.setVehicles(vehicles);
  }

  resetOrder(allocation: OrderAllocation, source: VehicleAllocation): void {
    source.slots.forEach(slot => {
      slot.pins = slot.pins.filter(i => i !== allocation);
    });
    this.orderAllocations.value.push(allocation);
    this.orderAllocations.observable.emit(this.orderAllocations.value);
    this.makeDirty(source);
  }

  makeDirty(veh: VehicleAllocation): void {
    if (veh.load.id) {
      this.dirty.updates[veh.load.id] = veh;
    } else {
      this.dirty.creates.push(veh);
    }
    veh.commit();
    this.debounceSave();
  }

  makeDirtyAndSave(veh: VehicleAllocation): void {
    if (veh.load.id) {
      this.dirty.updates[veh.load.id] = veh;
    } else {
      this.dirty.creates.push(veh);
    }
    veh.commit();
    this.saveLoad(veh.load);
  }

  commitAllDirty(): void {
    this.dirty.creates.forEach(c => c.commit());
    Object.values(this.dirty.updates).forEach(u => u.commit());
  }

  setMasterRoutes(masterRoutes: MasterRoute[]): void {
    this.masterRoutes = masterRoutes;
  }

  getId(orderAllocation: OrderAllocation): number {
    return orderAllocation.loadOrder.id;
  }

  getCustomerId(orderAllocation: OrderAllocation): number {
    return orderAllocation.loadOrder?.order.customer?.id;
  }

  getAddress(orderAllocation: OrderAllocation): string {
    const { deliveryAddress } = orderAllocation.loadOrder?.order;
    if (!deliveryAddress) {
      return 'Missing';
    }
    return deliveryAddress.getOneLiner();
  }

  getWeight(orderAllocation: OrderAllocation): number {
    return orderAllocation.weight;
  }

  getVolume(orderAllocation: OrderAllocation): number {
    return orderAllocation.volume;
  }

  changeSortingColumn(column: string): void {
    this.sortingData = { column, direction: 'ASC' };
  }

  changeSortingDirection() {
    this.sortingData.direction = this.sortingData.direction === 'ASC' ? 'DESC' : 'ASC';
  }

  sortOrderAllocations() {
    const sortingColumn = this.orderAllocationColumns.find(item => item.name === this.sortingData.column);
    const { getSortValue } = sortingColumn;
    if (typeof getSortValue(this.orderAllocations.value[0]) === 'number') {
      this.orderAllocations.value.sort((a, b) => {
        return (getSortValue(a) as number) - (getSortValue(b) as number);
      });
    } else if (typeof getSortValue(this.orderAllocations.value[0]) === 'string') {
      this.orderAllocations.value.sort((a, b) => {
        return (getSortValue(a) as string).localeCompare(getSortValue(b) as string);
      });
    }

    if (this.sortingData.direction === 'DESC') {
      this.orderAllocations.value.reverse();
    }
  }

  updateOrderDateRange(dateRange: DateRange) {
    this.orderDateRange = dateRange;
    this.debounceFetch();
  }

  async splitOrder(order: OrderAllocation, orderItems: SplitParam[], name: string): Promise<void> {
    const newOrder = order.splitOrder(orderItems);
    newOrder.loadOrder.name = name;
    const insertIndex = this.orderAllocations.value.indexOf(order);

    await this.saveLoadOrder(newOrder.loadOrder, order.loadOrder);

    const newOrders = [
      ...this.orderAllocations.value.slice(0, insertIndex + 1),
      newOrder,
      ...this.orderAllocations.value.slice(insertIndex + 1),
    ];
    this.setOrderAllocations(newOrders);
  }

  public flush() {
    this.date = {
      value: new Date(),
      observable: new EventEmitter<Date>(),
    };

    this.orderAllocations = {
      value: [],
      map: {},
      all: [],
      observable: new EventEmitter<OrderAllocation[]>(),
    };

    this.orderAllocationsFilter = {
      customerSearchTerm: null,
      masterRoute: null,
    };

    this.customerIdsByMasterRoute = [];

    this.vehicleAllocation = {
      value: [],
      map: {},
      all: [],
      observable: new EventEmitter<VehicleAllocation[]>(),
    };

    this.vehicleIdToAllocationMap = {};

    this.vehicleTemplates = {
      value: [],
      observable: new EventEmitter<VehicleTemplate[]>(),
    };

    this.vehicleClasses = {
      value: [],
      observable: new EventEmitter<VehicleClass[]>(),
    };

    this.vehicleFilter = {
      searchTerm: null,
      vehicleClassFilter: null,
      activeFilter: LoadFilter.Planning,
    };

    this.dirty = {
      updates: {},
      creates: [],
    };

    this.allocatedOrderIds = {};

    this.masterRoutes = [];

    this._orderDateRange = {
      type: 'date-range',
      to: new Date(),
    };

    this.orderAllocationColumns = [
      { name: 'Order ID', getSortValue: this.getId },
      { name: 'Customer ID', getSortValue: this.getCustomerId },
      // { name: 'Region', getSortValue: this.getAddress },
      { name: 'Weight', getSortValue: this.getWeight },
      { name: 'Volume', getSortValue: this.getVolume },
    ];

    this.sortingData = {
      column: 'id',
      direction: 'ASC',
    };
  }
}
