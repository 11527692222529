import { NgModule } from '@angular/core';
import { OvGenericModule } from '@ov-suite/ui';
import { ExceptionCommon } from '@ov-suite/models-shared';

@NgModule({
  imports: [
    OvGenericModule.forRoot({
      entity: ExceptionCommon,
      api: 'warehouselink',
      createTitle: 'Exception',
      route: ['exceptions'],
      tableTitle: 'Exception',
      hideAdd: true,
      hideImport: true,
      hideEditDelete: true,
      defaultOrderDirection: 'DESC',
      defaultOrderColumn: 'createdDate',
    }),
  ],
})
export class ExceptionLogModule {}
