import { OrderItem } from '@ov-suite/models-order';
import { v4 } from 'uuid';
import { LoadOrderItemModel, LoadOrderModel } from '@ov-suite/models-warehouse';

export interface SplitParam {
  orderItem: OrderItem;
  quantity?: number;
}

export class OrderAllocation {
  id: string = v4();

  loadOrder: LoadOrderModel;

  isPart = false;

  weight = 0;

  volume = 0;

  siblings: OrderAllocation[] = [];

  allocated = false;

  isPartial = false;

  part = 1;

  splitOrder(input: SplitParam[]): OrderAllocation {
    const newOrder = this.loadOrder.split(input);
    const newSibling = Object.assign(new OrderAllocation(), {
      loadOrder: newOrder,
      allocated: false,
      link: this,
      isPartial: true,
    } as Partial<OrderAllocation>);

    this.isPartial = true;

    this.addSibling(newSibling);
    this.computeIndexes();
    newSibling.computeMetrics();
    this.computeMetrics();
    return newSibling;
  }

  addSibling(sibling: OrderAllocation) {
    this.siblings.forEach(s => {
      sibling.siblings.push(s);
    });
    this.siblings.push(sibling);
    sibling.siblings.push(this);
  }

  moveItemsTo(target: OrderAllocation, params: SplitParam[]): void {
    params.forEach(param => {
      const local = this.loadOrder.loadOrderItems.find(i => i.orderItem.id === param.orderItem.id);
      const other = target.loadOrder.loadOrderItems.find(i => i.orderItem.id === param.orderItem.id);
      if (local && other) {
        local.quantity -= param.quantity;
        other.quantity += param.quantity;
      } else {
        target.addOrderItemAllocations([local]);
        this.removeOrderItemAllocations([local]);
      }
    });
    target.computeMetrics();
    this.computeMetrics();
  }

  resetInto(sibling: OrderAllocation): void {
    sibling.addOrderItemAllocations(this.loadOrder.loadOrderItems);
    this.loadOrder.loadOrderItems = [];
  }

  addOrderItemAllocations(items: LoadOrderItemModel[]): void {
    items.forEach(item => {
      const found = this.loadOrder.loadOrderItems.find(i => i.orderItem === item.orderItem);
      if (found) {
        found.quantity += item.quantity;
      } else {
        this.loadOrder.loadOrderItems.push(item);
      }
    });
    this.computeMetrics();
  }

  removeOrderItemAllocations(items: LoadOrderItemModel[]): void {
    this.loadOrder.loadOrderItems = this.loadOrder.loadOrderItems.filter(o => !items.includes(o));
  }

  computeIndexes(): void {
    let mainOrder: OrderAllocation;
    if (this.part === 1) {
      mainOrder = this;
    } else {
      mainOrder = this.siblings.find(item => item.part === 1);
    }
    mainOrder.siblings.forEach((sibling, index) => {
      sibling.part = index + 2;
    });
  }

  /**
   * Recalculates the current Weight and Volume
   */
  computeMetrics(): void {
    this.weight = getOrderItemsWeight(this.loadOrder.loadOrderItems);
    this.volume = getOrderItemsVolume(this.loadOrder.loadOrderItems);
  }

  static fromLoadOrder(loadOrder: LoadOrderModel): OrderAllocation {
    const totalItems = loadOrder.order.orderItems.reduce((p, c) => p + c.quantity, 0);
    const currentItems = loadOrder.loadOrderItems.reduce((p, c) => p + c.quantity, 0);
    const output = Object.assign(new OrderAllocation(), {
      loadOrder,
      // orderItemAllocations: loadOrder.loadOrderItems.map(OrderItemAllocation.fromLoadOrderItem),
      isPartial: totalItems !== currentItems,
    } as Partial<OrderAllocation>);
    output.computeMetrics();
    return output;
  }
}

// export class OrderItemAllocation {
//   id: string = v4();
//
//   quantity: number;
//
//   orderItem: OrderItem;
//
//   static fromOrderItem(orderItem: OrderItem): OrderItemAllocation {
//     return Object.assign(new OrderItemAllocation(), {
//       quantity: orderItem.quantity,
//       orderItem,
//     } as Partial<OrderItemAllocation>)
//   }
//
//   static fromLoadOrderItem(loadOrderItem: LoadOrderItemModel): OrderItemAllocation {
//     return Object.assign(new OrderItemAllocation(), {
//       quantity: loadOrderItem.quantity,
//       orderItem: loadOrderItem.orderItem,
//     } as Partial<OrderItemAllocation>)
//   }
//
//   static fromSplitParam(param: SplitParam): OrderItemAllocation {
//     return Object.assign(new OrderItemAllocation(), {
//       quantity: param.quantity ?? param.orderItem.quantity,
//       orderItem: param.orderItem,
//     } as Partial<OrderItemAllocation>);
//   }
// }

function round(amount: number, decimal: number = 2): number {
  return Math.round(amount * 10 ** decimal) / 10 ** decimal;
}

function getOrderItemsWeight(orderItems: LoadOrderItemModel[]): number {
  return round(orderItems.reduce((p, c) => p + c.orderItem.productSku?.weight * c.quantity, 0) / 1000);
}

function getOrderItemsVolume(orderItems: LoadOrderItemModel[]): number {
  return round(
    orderItems.reduce(
      (p, c) =>
        p +
        (c.orderItem.productSku?.width / 100) *
          (c.orderItem.productSku?.length / 100) *
          (c.orderItem.productSku?.height / 100) *
          c.quantity,
      0,
    ),
  );
}
