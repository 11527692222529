import { EventEmitter } from '@angular/core';
import { LoadAllocation, WaveInstance } from '@ov-suite/models-warehouse';
import { InventoryLocation } from '@ov-suite/models-admin';

export interface SingleCombo<T> {
  value: T;
  observable: EventEmitter<T>;
}

export interface ListCombo<T> {
  value: T[];
  map?: Record<number, T>;
  all?: T[];
  observable: EventEmitter<T[]>;
}

export class LoadPlan {
  load?: LoadAllocation;

  wave?: WaveInstance;

  loadBay?: InventoryLocation;

  error?: string;

  static fromLoad(load: LoadAllocation) {
    const output = new LoadPlan();
    output.load = load;
    return output;
  }
}
