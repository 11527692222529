import { Component, OnInit } from '@angular/core';
import { OVTag } from '@ov-suite/ov-metadata';
import { NgbAlertConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { InventoryProductSku, ProductSkuLevelsModel } from '@ov-suite/models-warehouse';
import { InventoryThreshold, ProductSku } from '@ov-suite/models-admin';
import { OvAutoService } from '@ov-suite/services';

@OVTag('set-triggers-popup', InventoryProductSku)
@Component({
  selector: 'ov-suite-inventory-triggers-modal',
  templateUrl: './set-triggers.component.html',
  styleUrls: ['./set-triggers.component.scss'],
  providers: [NgbAlertConfig],
})
export class SetTriggersComponent implements OnInit {
  inventoryProductSku: ProductSkuLevelsModel;

  loading: boolean;

  onSuccess: boolean;

  triggerStatus = 'info';

  error = '';

  inventoryThreshold: InventoryThreshold = new InventoryThreshold();

  constructor(protected alertConfig: NgbAlertConfig, protected modalService: NgbModal, protected ovAutoService: OvAutoService) {
    alertConfig.type = 'success';
    alertConfig.dismissible = false;
    this.inventoryThreshold.redTriggerValue = 0;
    this.inventoryThreshold.amberTriggerValue = 0;
  }

  getStatus(value: number, inventoryThreshold: InventoryThreshold) {
    if (value <= inventoryThreshold.redTriggerValue) {
      return 'danger';
    }
    if (value <= inventoryThreshold.amberTriggerValue) {
      return 'warning';
    }
    return 'success';
  }

  ngOnInit(): void {
    this.ovAutoService
      .list({
        limit: 1,
        entity: InventoryThreshold,
        filter: {},
        query: { skuId: [this.inventoryProductSku.productSku.id] },
      })
      .then(res => {
        if (res.data.length) {
          const [trigger] = res.data;
          this.inventoryThreshold = trigger;
          this.triggerStatus = this.getStatus(this.inventoryProductSku?.quantity, this.inventoryThreshold);
        }
      })
      .catch(error => {
        console.log(error);
      });
  }

  onTriggerChange(event) {
    this.error = '';
    this.triggerStatus = this.getStatus(this.inventoryProductSku?.quantity, this.inventoryThreshold);
  }

  onSave() {
    this.onSuccess = false;

    if (this.inventoryThreshold.redTriggerValue === 0 && this.inventoryThreshold.amberTriggerValue === 0) {
      this.error = 'Triggers Required';
      return;
    }

    if (this.inventoryThreshold.redTriggerValue > this.inventoryThreshold.amberTriggerValue) {
      this.error = 'Red Trigger must be lower than Amber';
      return;
    }

    this.loading = true;
    const onSuccess = (res: InventoryThreshold) => {
      this.inventoryThreshold = res;
      this.onSuccess = true;
      this.triggerStatus = this.getStatus(this.inventoryProductSku?.quantity, this.inventoryThreshold);
      this.close();
      window.location.reload();
    };

    if (this.inventoryThreshold.id) {
      this.ovAutoService
        .update({ entity: InventoryThreshold, item: this.inventoryThreshold })
        .then(onSuccess)
        .catch()
        .finally(() => {
          this.loading = false;
        });
    } else {
      this.inventoryThreshold.sku = {} as ProductSku;
      this.inventoryThreshold.sku.id = this.inventoryProductSku.productSku?.id;
      this.ovAutoService
        .create({ entity: InventoryThreshold, item: this.inventoryThreshold })
        .then(onSuccess)
        .catch()
        .finally(() => {
          this.loading = false;
        });
    }
  }

  close = () => {
    this.modalService.dismissAll();
  };
}
