import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { WaveAllocationDragService } from '../wave-allocation.drag.service';
import { WaveAllocationDataService } from '../wave-allocation.data.service';
import { WaveAllocationBaseService } from '../wave-allocation.base.service';

@Component({
  selector: 'ov-suite-wave-allocation-planner',
  templateUrl: './wave-allocation-planner.component.html',
  styleUrls: ['./wave-allocation-planner.component.scss'],
})
export class WaveAllocationPlannerComponent implements AfterViewInit {
  @ViewChild('body') body: ElementRef<HTMLDivElement>;
  @ViewChild('leftColumn') leftColumn: ElementRef<HTMLDivElement>;
  @ViewChild('topRow') topRow: ElementRef<HTMLDivElement>;

  constructor(
    public readonly dataService: WaveAllocationDataService,
    public readonly baseService: WaveAllocationBaseService,
    public readonly dragService: WaveAllocationDragService,
  ) {}

  ngAfterViewInit() {
    this.dragService.setElements(this.body.nativeElement, this.leftColumn.nativeElement, this.topRow.nativeElement);
  }
}
