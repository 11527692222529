<div
  cdkDropList
  [id]="pinId"
  class="wave-bay-plan"
  cdkDropListOrientation="horizontal"
  [cdkDropListData]="waveBay"
  [cdkDropListConnectedTo]="baseService.lists"
  (cdkDropListDropped)="actionService.drop($event, wave, loadBay)"
  [cdkDropListDisabled]="!baseService.permissions.create"
  (mousedown)="!waveBay.length && dragService.drag($event)"
>
  <div
    *ngFor="let pin of waveBay; let i = index"
    [cdkDragData]="pin"
    (click)="actionService.selectLoad(pin.load)"
    class="wave-bay-pin"
    [ngClass]="{
      'conflict-overbooked': waveBay.length > 1,
      'conflict-vehicle': actionService.vehicleConflicts[pin.load.id],
      locked: !!pin.load.releaseDate
    }"
    cdkDrag
    [cdkDragDisabled]="!!pin.load.releaseDate"
    [ngbTooltip]="actionService.vehicleConflicts[pin.load.id] ? 'This vehicle has already been planned for this wave' : null"
  >
    <div class="wave-bay-pin-left">
      <span class="detail-line detail-line-title">Load ID: {{ pin.load.id }}</span>
      <span class="detail-line">Vehicle: {{ pin.load.getVehicle()?.registration }}</span>
      <span class="detail-line">{{ getCustomerString(pin) }}</span>
      <span class="detail-line" *ngIf="waveBay.length <= 1 && pin.load.releaseDate">{{ pin.load.releaseDate | date }}</span>
      <span class="detail-line" *ngIf="pin.error">Error: {{ pin.error }}</span>
    </div>
    <i
      *ngIf="waveBay?.length"
      class="fa fa-ellipsis-v custom-wave-release-pin"
      placement="right"
      [ngbPopover]="loadSettingsContent"
      aria-hidden="true"
    ></i>

    <ng-template #loadSettingsContent>
      <div class="pop-menu">
        <button [disabled]="!baseService.permissions.create || hasBeenReleased(waveBay)" (click)="onRelease(pin)" class="btn btn-sm">
          Release
        </button>
        <button
          [disabled]="!baseService.permissions.create || hasBeenReleased(waveBay)"
          (click)="onRemove(i)"
          class="btn btn-sm btn-danger"
        >
          Remove
        </button>
      </div>
    </ng-template>
  </div>
</div>
