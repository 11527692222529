import { Component } from '@angular/core';
import { HierarchyTab } from '@ov-suite/ui';
import { ProductSkuLevelsModel } from '@ov-suite/models-warehouse';
import { QueryParams } from '@ov-suite/helpers-shared';

@Component({
  selector: 'ov-suite-inventory-product-sku',
  templateUrl: './inventory-product-sku-list.component.html',
  styleUrls: ['./inventory-product-sku-list.component.scss'],
})
export class InventoryProductSkuListComponent {
  parentId?: number;

  // Class - Required
  formClass = ProductSkuLevelsModel;

  filter: Record<string, QueryParams[]> = {
    exitTime: [{ operator: 'IS', value: null }],
  };

  tabs: HierarchyTab[] = [
    { title: 'Products', iconClass: '', path: '/inventory-product' },
    { title: 'Locations', iconClass: '', path: '/inventory-locations' },
  ];
}
