import { OVEntity, OVField, OVTable } from '@ov-suite/ov-metadata';
import { Rag } from '@ov-suite/helpers-shared';
import { InventoryLocation } from '@ov-suite/models-admin';

@OVTable<InventoryLocationCountModel>([
  {
    title: 'Name',
    type: 'deep-string',
    key: 'inventoryLocation.name',
  },
  {
    title: 'description',
    type: 'deep-string',
    key: 'inventoryLocation.description',
  },
  // {
  //   title: 'Last Updated',
  //   type: 'date-time',
  //   key: 'timestamp',
  // },
  {
    title: 'Count',
    type: 'number',
    key: 'quantity',
  },
  // {
  //   title: 'Total Count',
  //   type: 'number',
  //   key: 'totalCount',
  // },
  // {
  //   title: 'Promise Count',
  //   type: 'number',
  //   key: 'promiseCount',
  // },
  // {
  //   title: 'On Hand',
  //   type: 'other',
  //   action: (val) => `${val.totalCount - val.promiseCount}`,
  //   keys: ['totalCount', 'promiseCount'],
  // },
  {
    title: 'Status',
    type: 'other',
    action: val => {
      switch (val.rag) {
        case Rag.amber:
          return `<span style="color: orange">●</span>`;
        case Rag.green:
          return `<span style="color: green">●</span>`;
        case Rag.red:
          return `<span style="color: red">●</span>`;
        default:
          return 'missing';
      }
    },
    keys: ['rag', 'inventoryLocation.id'],
  },
])
@OVEntity({ name: 'InventoryLocationLevels' })
export class InventoryLocationCountModel {
  @OVField({ type: 'number' })
  id: number;

  @OVField({ type: () => InventoryLocation })
  inventoryLocation: InventoryLocation;

  @OVField({ type: 'number' })
  quantity: number;

  @OVField({ type: 'date' })
  enterTime: Date;

  @OVField({ type: 'date' })
  exitTime?: Date;

  @OVField({ type: 'number' })
  rag: Rag;
}
