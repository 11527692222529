import { Routes } from '@angular/router';
import { AdminLayoutComponent, RouteInfo } from '@ov-suite/ui';

export const angularRoutes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full',
  },
  {
    path: '',
    component: AdminLayoutComponent,
    children: [
      {
        path: 'flowable',
        loadChildren: () => import('./modules/process-definition/process-definition.module').then(m => m.ProcessDefinitionModule),
      },
      {
        path: 'inventory',
        loadChildren: () => import('./modules/inventory/inventory.module').then(m => m.InventoryModule),
      },
      {
        path: 'inventory-product',
        loadChildren: () => import('./modules/inventory-product-sku/inventory-product-sku.module').then(m => m.InventoryProductSkuModule),
      },
      {
        path: 'inventory-locations',
        loadChildren: () => import('./modules/inventory-location/inventory-location.module').then(m => m.InventoryLocationModule),
      },
      {
        path: 'process-definitions',
        loadChildren: () => import('./modules/process-definition/process-definition.module').then(m => m.ProcessDefinitionModule),
      },
      {
        path: 'workflow-processes',
        loadChildren: () => import('./modules/workflow-process/workflow-process.module').then(m => m.WorkflowProcessModule),
      },
      {
        path: 'load-allocation',
        loadChildren: () => import('./modules/load-allocation/load-allocation.module').then(m => m.LoadAllocationModule),
      },
      {
        path: 'wave-allocation',
        loadChildren: () => import('./modules/wave-allocation/wave-allocation.module').then(m => m.WaveAllocationModule),
      },
      {
        path: 'waves',
        loadChildren: () => import('./modules/waves/waves.module').then(m => m.WavesModule),
      },
      {
        path: 'vehicles',
        loadChildren: () => import('./modules/vehicles/vehicles.module').then(m => m.VehiclesModule),
      },
      {
        path: 'master-route',
        loadChildren: () => import('./modules/master-route/master-route.module').then(m => m.MasterRouteModule),
      },
      {
        path: 'dashboard',
        loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule),
      },
      {
        path: 'exceptions',
        loadChildren: () => import('./modules/exception-log/exception-log.module').then(m => m.ExceptionLogModule),
      },
      {
        path: 'resources',
        loadChildren: () => import('./modules/resource/resource.module').then(m => m.ResourceModule),
      },
      {
        path: 'container-events',
        loadChildren: () =>
          import('./modules/pallet-audit-trail-events/inventory-container-events.module').then(m => m.InventoryContainerEventsModule),
      },
      {
        path: 'inventory-container',
        loadChildren: () => import('./modules/inventory-container/inventory-container.module').then(m => m.InventoryContainerModule),
      },
      {
        path: 'orders',
        loadChildren: () => import('./modules/orders/orders.module').then(m => m.OrdersModule),
      },
      {
        path: 'completed-orders',
        loadChildren: () => import('./modules/orders/completed-orders.module').then(m => m.CompletedOrdersModule),
      },
      {
        path: 'order-events',
        loadChildren: () =>
          import('./modules/orders-audit-trail-events/orders-audit-trail-event.module').then(m => m.OrdersAuditTrailEventModule),
      },
      {
        path: 'resource-exception',
        loadChildren: () => import('./modules/resource-exception/exception-log.module').then(m => m.ResourceExceptionModule),
      },
      {
        path: 'notifications',
        loadChildren: () => import('@ov-suite/services-angular').then(m => m.NotificationsListModule),
      },
    ],
  },
];

export const sidebarRoutes: RouteInfo[] = [
  {
    path: '/dashboard',
    title: 'dashboard',
    icontype: 'fa fa-pie-chart',
    type: 'link',
  },
  {
    path: '/',
    title: 'Load Setup',
    type: 'sub',
    collapse: 'loading-management',
    icontype: 'fa fa-tasks',
    children: [
      { path: 'waves', title: 'Picking Waves', ab: 'PW', permissionId: 101 },
      { path: 'vehicles', title: 'Vehicles', ab: 'V', permissionId: 102 },
      { path: 'master-route', title: 'Routes', ab: 'R', permissionId: 103 },
    ],
  },
  {
    path: '/',
    title: 'Load Planning',
    type: 'sub',
    collapse: 'loading-planning',
    icontype: 'fa fa-calendar-minus-o',
    children: [
      { path: 'load-allocation', title: 'Load Allocation', ab: 'LA', permissionId: 104 },
      { path: 'wave-allocation', title: 'Wave Allocation', ab: 'WA', permissionId: 105 },
      { path: 'orders', title: 'Orders', ab: 'O', permissionId: 105 },
    ],
  },
  {
    path: '/',
    title: 'Inventory',
    type: 'sub',
    collapse: 'inventory',
    icontype: 'fa fa-plus-circle',
    children: [
      // { path: 'inventory', title: 'Inventory List', ab: 'I', permissionId: 106 },
      { path: 'inventory-product', title: 'Inventory Product', ab: 'IP', permissionId: 107 },
    ],
  },
  {
    path: '/',
    title: 'Monitoring',
    type: 'sub',
    collapse: 'monitoring',
    icontype: 'fa fa-tachometer',
    children: [
      { path: 'resource-exception', title: 'Resource Exceptions', ab: 'RE', permissionId: 111 },
      { path: 'exceptions', title: 'Exceptions', ab: 'E', permissionId: 110 },
      { path: 'order-events', title: 'Order Events', ab: 'OE', permissionId: 111 },
      { path: 'container-events', title: 'Pallet Events', ab: 'PE', permissionId: 111 },
      // { path: 'process-definitions', title: 'Process Definitions', ab: 'PD', permissionId: 108 },
      // { path: 'workflow-processes', title: 'Workflow Processes', ab: 'WP', permissionId: 109 },
    ],
  },
  {
    path: '/',
    title: 'General setup',
    type: 'sub',
    collapse: 'general-setup',
    icontype: 'fa fa-wrench',
    children: [
      { path: 'resources', title: 'Resources', ab: 'R', permissionId: 111 },
      { path: 'inventory-container', title: 'Inventory Container', ab: 'IC', permissionId: 111 },
    ],
  },
];
