<ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs">
  <li [ngbNavItem]="1">
    <a ngbNavLink>Order Items</a>
    <ng-template ngbNavContent>
      <table class="table table-sm" id="order-list">
        <thead>
          <tr>
            <th></th>
            <th>SKU Name</th>
            <th>Qty</th>
            <th>*</th>
          </tr>
        </thead>
        <tbody id="order-item-list">
          <tr *ngFor="let orderItem of action.orderSelected.value?.loadOrder.loadOrderItems" class="border">
            <td>
              <input type="checkbox" (change)="onCheck(orderItem)" [checked]="splitItems.includes(orderItem)" />
            </td>
            <td>{{ orderItem.orderItem.productSku?.sku }}</td>
            <td>{{ orderItem?.quantity }}</td>
            <td></td>
          </tr>
        </tbody>
      </table>
      <div class="row" style="padding: 5%">
        <button class="btn btn-primary" [disabled]="!splitItems.length" (click)="splitOrder()">Split</button>
        <button
          *ngIf="action.orderSelected.value.isPartial"
          class="btn btn-primary"
          [disabled]="!action.selectedOrderPart"
          (click)="moveOrder()"
        >
          Move
        </button>
      </div>
    </ng-template>
  </li>
  <li [ngbNavItem]="2">
    <a ngbNavLink>Order Info</a>
    <ng-template ngbNavContent>
      <div class="simple-table">
        <div class="simple-row">
          <div>Priority</div>
          <div>
            <span [class]="'badge ' + orderPriority[action.orderSelected.value?.loadOrder?.order?.priority]?.color">
              {{ orderPriority[action.orderSelected.value?.loadOrder?.order?.priority]?.text }}
            </span>
          </div>
        </div>
        <div class="simple-row">
          <div>Order ID</div>
          <div>{{ action.orderSelected.value?.loadOrder?.order?.orderCode }}</div>
        </div>
        <div class="simple-row">
          <div>Order Placement Date</div>
          <div>{{ action.orderSelected.value?.loadOrder?.order?.orderDate | date }}</div>
        </div>
        <div class="simple-row">
          <div>Delivery Date</div>
          <div>{{ action.orderSelected.value?.loadOrder?.order?.fulfilmentDate }}</div>
        </div>
        <div class="simple-row">
          <div>SLA Remaining</div>
          <div>
            {{
              getSLA(action.orderSelected.value?.loadOrder?.order.orderDate, action.orderSelected.value?.loadOrder?.order?.fulfilmentDate)
            }}
          </div>
        </div>
        <div class="simple-row">
          <div>Total Weight</div>
          <div>{{ action.orderSelected.value?.weight }} kg</div>
        </div>
        <div class="simple-row">
          <div>Total Volume</div>
          <div>{{ action.orderSelected.value?.volume }} m³</div>
        </div>
      </div>
    </ng-template>
  </li>
  <li [ngbNavItem]="3">
    <a ngbNavLink>Customer Details</a>
    <ng-template ngbNavContent>
      <div class="simple-table">
        <div class="simple-row">
          <div>Name</div>
          <div>{{ action.orderSelected.value?.loadOrder.order.customer.name }}</div>
        </div>
        <div class="simple-row">
          <div>Code</div>
          <div>{{ action.orderSelected.value?.loadOrder.order.customer.customerCode }}</div>
        </div>
        <div class="simple-row">
          <div>Description</div>
          <div>{{ action.orderSelected.value?.loadOrder.order.customer.description }}</div>
        </div>
      </div>
    </ng-template>
  </li>
</ul>

<div [ngbNavOutlet]="nav" class="mt-2"></div>
