<div class="card-body">
  <div class="d-flex justify-content-between">
    <h5 class="ml-auto mr-auto" *ngIf="!moveTarget">Split Items</h5>
    <h5 class="ml-auto mr-auto" *ngIf="moveTarget">Move Items</h5>
    <h5 class="justify-content-end align-self-center" (click)="close()"><i aria-hidden="true" class="fa fa-times"></i></h5>
  </div>
  <span class="font-weight-bold d-flex justify-content-center">How many items would you like to split from the order?</span>
  <div class="d-flex flex-column justify-content-center px-4">
    <div class="form-group col-md-12" *ngIf="!moveTarget">
      <label>Name</label>
      <div>
        <input class="form-control" type="text" [(ngModel)]="name" [required]="true" />
      </div>
    </div>

    <div *ngFor="let item of splitParams; let i = index" class="form-group">
      <div class="d-flex">
        <div class="col-md-12">
          <label>
            {{ item.orderItem.productSku.sku }}
          </label>
          <div>
            <input class="form-control" type="number" [max]="item.max" [min]="0" [(ngModel)]="item.quantity" step="1" />
          </div>
        </div>
        <i
          *ngIf="splitParams.length > 1"
          aria-hidden="true"
          class="fa fa-times align-self-center mr-2 ml-1 mt-4"
          (click)="removeItem(item, i)"
        ></i>
      </div>
    </div>
    <div class="d-flex justify-content-between">
      <button class="btn btn-outline-primary ml-3" (click)="close()">Cancel</button>
      <button *ngIf="!moveTarget" class="btn btn-outline-dark mr-4" (click)="split()" [disabled]="!name">Split</button>
      <button *ngIf="moveTarget" class="btn btn-primary" (click)="move()">Move</button>
    </div>
  </div>
</div>
