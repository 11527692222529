import { EventEmitter, Injectable } from '@angular/core';
import { CdkDragDrop, CdkDragRelease, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { SingleCombo, VehicleAllocation } from './load-allocation.interface';
import { LoadAllocationDataService } from './load-allocation.data.service';
import { OrderAllocation } from './classes/order-allocation.class';

/**
 * This service is used to manage interaction between panels on load-allocation.
 *
 * This typically means user actions like hovering and selecting
 */

@Injectable()
export class LoadAllocationActionService {
  vehicleSelected: SingleCombo<VehicleAllocation> = {
    value: null,
    observable: new EventEmitter<VehicleAllocation>(),
  };

  selectedVehicles: Record<string, VehicleAllocation> = {};

  orderSelected: SingleCombo<OrderAllocation> = {
    value: null,
    observable: new EventEmitter<OrderAllocation>(),
  };

  orderHoverStart = new EventEmitter<OrderAllocation>();

  orderHoverEnd = new EventEmitter<OrderAllocation>();

  ordersChangeTracker: Record<number, { destination: string; origin: string }>;

  selectedOrderPart?: OrderAllocation;

  allVehiclesSelected: boolean;

  constructor(private readonly data: LoadAllocationDataService) {}

  public startUp() {}

  selectOrder(order: OrderAllocation) {
    if (this.orderSelected.value !== order) {
      this.clearOrderPart();
    }
    this.orderSelected.value = order;
    this.orderSelected.observable.emit(order);
  }

  clearOrder() {
    this.orderSelected.value = null;
    this.orderSelected.observable.emit(null);
  }

  getPropertyKey(vehicle: VehicleAllocation) {
    if (vehicle.load.vehicle) {
      return 'vehicle';
    }
    if (vehicle.load.externalVehicle) {
      return 'externalVehicle';
    }
    return 'unknown type';
  }

  selectVehicle(vehicle: VehicleAllocation) {
    this.vehicleSelected.value = vehicle;
    const propertyKey = this.getPropertyKey(vehicle);
    if (vehicle.load[propertyKey].id in this.selectedVehicles) {
      delete this.selectedVehicles[vehicle.load[propertyKey].id];
      this.allVehiclesSelected = false;
    } else {
      this.selectedVehicles[vehicle.load[propertyKey].id] = vehicle;
    }
    this.vehicleSelected.observable.emit(vehicle);
  }

  isSelected(vehicle: VehicleAllocation) {
    const propertyKey = this.getPropertyKey(vehicle);
    return vehicle.load[propertyKey].id in this.selectedVehicles;
  }

  selectAllVehicles() {
    if (!this.allVehiclesSelected) {
      this.data.vehicleAllocation.value.forEach(vehicle => {
        const propertyKey = this.getPropertyKey(vehicle);
        this.selectedVehicles[vehicle.load[propertyKey].id] = vehicle;
      });
    } else {
      this.data.vehicleAllocation.value.forEach(vehicle => {
        const propertyKey = this.getPropertyKey(vehicle);
        delete this.selectedVehicles[vehicle.load[propertyKey].id];
      });
    }
    this.allVehiclesSelected = !this.allVehiclesSelected;
  }

  releaseDirty(event: CdkDragRelease<OrderAllocation>, veh: VehicleAllocation): void {
    this.data.makeDirty(veh);
  }

  dropDirty(event: CdkDragDrop<OrderAllocation[]>, veh: VehicleAllocation) {
    this.drop(event);
    this.data.makeDirty(veh);
    this.data.commitAllDirty();
  }

  drop(event: CdkDragDrop<OrderAllocation[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(event.previousContainer.data, event.container.data, event.previousIndex, event.currentIndex);
    }
    this.vehicleSelected.observable.emit(this.vehicleSelected.value);
  }

  changesDetector(event: CdkDragDrop<OrderAllocation[]>) {
    // TODO add more logic
    if (event.previousContainer.id !== event.container.id) {
      this.ordersChangeTracker = {
        ...this.ordersChangeTracker,
        [Number(event.item.data?.order?.id)]: {
          destination: event.container.id,
          origin: event.previousContainer.id,
        },
      };
    }
  }

  selectOrderPart(orderPart: OrderAllocation) {
    if (this.selectedOrderPart) {
      this.selectedOrderPart = null;
      return;
    }
    this.selectedOrderPart = orderPart;
  }

  clearOrderPart(): void {
    delete this.selectedOrderPart;
  }

  flush() {
    this.vehicleSelected = {
      value: null,
      observable: new EventEmitter<VehicleAllocation>(),
    };

    this.selectedVehicles = {};

    this.orderSelected = {
      value: null,
      observable: new EventEmitter<OrderAllocation>(),
    };

    this.orderHoverStart = new EventEmitter<OrderAllocation>();

    this.orderHoverEnd = new EventEmitter<OrderAllocation>();

    this.ordersChangeTracker = undefined;

    this.selectedOrderPart = undefined;

    this.allVehiclesSelected = undefined;
  }
}
