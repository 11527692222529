<div class="order-list-container">
  <div class="header-bar">
    <div class="header-left">
      <h6>Orders</h6>
    </div>
    <div class="header-right">
      <div ngbDropdown class="d-inline-block" placement="bottom-right" #filterDrop="ngbDropdown">
        <button id="load-allocation-filter-dropdown" class="fa fa-filter btn-primary" ngbDropdownToggle></button>
        <div ngbDropdownMenu class="filter-dropdown">
          <div>
            <div class="filter-box">
              <span class="ml-2">Customer Name or Code</span>
              <ov-suite-input
                class="customer-search"
                type="text"
                placeholder="Search"
                [(ngModel)]="dataService.orderAllocationsFilter.customerSearchTerm"
              >
              </ov-suite-input>
            </div>
            <div class="filter-box">
              <span class="ml-2">Date Range</span>
              <ov-suite-input
                type="date-range"
                placeholder="Date Range"
                [(ngModel)]="dataService.orderDateRange"
                [hideClearButton]="true"
                #dateRange
              >
              </ov-suite-input>
            </div>
            <div class="form-group filter-box">
              <span class="ml-2">Master Route</span>
              <ov-suite-input
                [id]="'masterRoute'"
                name="masterRoute"
                [type]="'dropdown'"
                [label]="['name']"
                [data]="dataService.masterRoutes"
                [(ngModel)]="dataService.orderAllocationsFilter.masterRoute"
                [placeholder]="'Select master route'"
              >
              </ov-suite-input>
            </div>
            <div class="filter-button-bar">
              <button (click)="clearOrderAllocationsFilter()" class="btn-sm btn-secondary mb-2">Clear</button>
              <button (click)="dataService.debounceFetch(); filterDrop.close()" class="btn-sm btn-primary mb-2">Apply</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <table class="table table-sm" *ngIf="!!dataService.orderAllocations.all.length">
    <thead>
      <tr>
        <th *ngFor="let col of dataService.orderAllocationColumns" scope="col" (click)="onColumnHeaderClick(col.name)">
          {{ col.name }}
          <i *ngIf="dataService.sortingData.column !== col.name" class="fa fa-fw fa-sort" aria-hidden="true"></i>
          <i
            *ngIf="dataService.sortingData.column === col.name"
            class="fa fa-fw"
            [ngClass]="{
              'fa-sort-asc': dataService.sortingData.direction === 'ASC',
              'fa-sort-desc': dataService.sortingData.direction === 'DESC'
            }"
            aria-hidden="true"
          ></i>
        </th>
      </tr>
    </thead>
    <tbody
      cdkDropList
      id="order-list"
      [cdkDropListData]="dataService.orderAllocations.value"
      [cdkDropListConnectedTo]="base.vehicleLists"
      (cdkDropListDropped)="actionService.drop($event)"
      [cdkDropListDisabled]="!base.permissions.create"
    >
      <tr
        *ngFor="let allocation of dataService.orderAllocations.value"
        class="border"
        cdkDrag
        [cdkDragData]="allocation"
        (click)="actionService.selectOrder(allocation)"
        (mouseenter)="actionService.orderHoverStart.emit(allocation)"
        (mouseleave)="actionService.orderHoverEnd.emit(allocation)"
        [ngClass]="{ 'selected-customer': actionService.orderSelected.value === allocation }"
      >
        <td>
          <div *ngIf="!allocation.isPartial; else sub">
            {{ allocation.loadOrder.order.orderCode }}
          </div>
          <ng-template #sub>
            <div style="display: flex; flex-direction: row">
              <div style="width: 15px">
                <input
                  type="checkbox"
                  *ngIf="
                    actionService.orderSelected.value?.loadOrder.order.id == allocation.loadOrder.order.id &&
                    actionService.orderSelected.value != allocation
                  "
                  [checked]="allocation == actionService.selectedOrderPart"
                  (click)="selectOrderPart($event, allocation)"
                />
              </div>
              <ov-suite-load-allocation-order-sub-label
                [label]="allocation.loadOrder.order.orderCode"
                [subLabel]="allocation.loadOrder.name || 'Original'"
              ></ov-suite-load-allocation-order-sub-label>
            </div>
          </ng-template>
        </td>
        <td>
          <ov-suite-load-allocation-order-sub-label
            [label]="allocation.loadOrder.order.customer?.customerCode"
            [subLabel]="allocation.loadOrder.order.customer?.name"
          ></ov-suite-load-allocation-order-sub-label>
        </td>
        <!--        <td>{{ allocation.order.location?.address }}</td>-->
        <td>{{ allocation.weight }} kg</td>
        <td>{{ allocation.volume }} m³</td>
        <div *cdkDragPlaceholder></div>
        <div *cdkDragPreview class="plan-pin">
          <span>{{ allocation.loadOrder.order.id }}</span>
          <span>{{ allocation.loadOrder.order.customer.name }} </span>
          <span>{{ allocation.weight }} kg</span>
          <span>{{ allocation.volume }} m³</span>
        </div>
      </tr>
    </tbody>
  </table>
  <div *ngIf="!dataService.orderAllocations.all.length" class="no-orders">
    <div>No orders found for {{ dataService.date.value | date }}</div>
  </div>
</div>
