<div class="planner-container">
  <div class="table-planner">
    <div class="planner-main">
      <div class="planner-corner">Load Bay</div>
      <div class="planner-head" #topRow (mousedown)="dragService.dragX($event)">
        <div
          class="planner-cell"
          *ngFor="let waves of dataService.waveDays?.value || []"
          [ngStyle]="{ 'min-width.px': waves.length * 150 - 2 }"
        >
          <div class="date-subsection">
            {{ waves[0].startDate | waveDate }}
          </div>
          <div class="header-waves-container">
            <div class="wave-item" *ngFor="let wave of waves">
              <div class="wave-name">
                {{ wave.waveConfig.waveName }}
              </div>
              <div class="wave-release-button">
                <button [disabled]="!baseService.permissions.create" class="btn btn-sm text-center" (click)="dataService.releaseWave(wave)">
                  <i class="fa fa-arrow-right" aria-hidden="true"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <span class="text-center" *ngIf="!dataService.waveDays?.value?.length">
      <h5 class="text-capitalize">No waves Available for this date range</h5>
    </span>
    <div class="planner-main">
      <div class="planner-bay-column" #leftColumn (mousedown)="dragService.dragY($event)">
        <div *ngFor="let bay of dataService.loadBays.value" class="planner-cell" style="background: #ebedf0">
          {{ bay.name }}
        </div>
      </div>
      <div #body class="planner-body">
        <span *ngFor="let bay of dataService.loadBays.value" class="planner-row">
          <ng-container *ngFor="let waves of dataService.waveDays.value">
            <div *ngFor="let wave of waves" class="planner-cell planner-cell-content">
              <ov-suite-wave-allocation-wave-bay [wave]="wave" [loadBay]="bay"> </ov-suite-wave-allocation-wave-bay>
            </div>
          </ng-container>
        </span>
      </div>
    </div>
  </div>
</div>
