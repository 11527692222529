import { Injectable } from '@angular/core';
import { NgbCalendar, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { OvAutoService } from '@ov-suite/services';
import { CRUD } from '@ov-suite/helpers-shared';
import { getWebCrud } from '@ov-suite/authguard-angular';
import { LoadAllocationDataService } from './load-allocation.data.service';

/**
 * This service is used to handle any common actions that aren't related to:
 *  Data ( use the Data Service )
 *  User interaction ( use the Action Service )
 *
 * This is not the place to put code relating to a single panel on load-allocation, that logic should be on that panels component:
 *  map - Top Left
 *  order - Top Right
 *  vehicle - Bottom Left. Also see: vehicle-layout service
 *  detail - Bottom Right
 */

@Injectable()
export class LoadAllocationBaseService {
  // Used by order and vehicle
  vehicleLists: string[] = [];

  permissions: CRUD;

  constructor(
    private readonly calendar: NgbCalendar,
    public readonly formatter: NgbDateParserFormatter,
    public readonly ovAutoService: OvAutoService,
    private readonly dataService: LoadAllocationDataService,
  ) {}

  public startUp() {
    this.dataService.vehicleAllocation.observable.subscribe(() => {
      this.updateLists();
    });
    getWebCrud(104).then(crud => {
      this.permissions = crud;
    });
  }

  updateLists(): void {
    const vehicleLists = ['order-list'];
    this.dataService.vehicleAllocation.all
      .filter(i => !i.load.commit)
      .forEach(veh => {
        veh.slots.forEach(slot => {
          const prefix = veh.load.isExternalVehicle() ? 'e' : 'i';
          vehicleLists.push(`veh_${veh.load.getVehicle().id}_${slot.id}_${prefix}`);
        });
      });
    this.vehicleLists = vehicleLists;
  }

  flush() {
    this.vehicleLists = [];

    this.permissions = undefined;
  }
}
