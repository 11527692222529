import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { WaveConfig, WaveInstance } from '@ov-suite/models-warehouse';

@Injectable()
export class WaveService {
  constructor(private readonly apollo: Apollo) {}

  async updateWaveConfig(item: Partial<WaveConfig>): Promise<{ id: number; changes: WaveInstance[] }> {
    const name = 'updateWaveConfig';
    return new Promise((resolve, reject) => {
      this.apollo
        .use('warehouselink')
        .mutate({
          mutation: gql(`mutation
            ${name}($data: WaveConfigUpdateInput!) {
            ${name}(data: $data) {
                id
                changes {
                  startDate
                }
            }
          }`),
          variables: {
            data: item,
          },
        })
        .subscribe(response => {
          resolve(response.data[name]);
        }, reject);
    });
  }
}
