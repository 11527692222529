import { Component, OnInit } from '@angular/core';
import { OvAutoService } from '@ov-suite/services';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Order, OrderItem } from '@ov-suite/models-order';
import { FieldMetadata, getFieldMetadata } from '@ov-suite/ov-metadata';
import { ColumnData } from '@ov-suite/helpers-shared';

@Component({
  selector: 'ov-suite-order-details-popup',
  templateUrl: './order-details-popup.component.html',
  styleUrls: ['./order-details-popup.component.scss'],
})
export class OrderDetailsPopupComponent implements OnInit {
  formClass = OrderItem;
  item: Order;
  metadata: FieldMetadata<OrderItem>;

  pageData: OrderItem[] = [];

  totalCount = 1;

  pageSize = 10;

  currentPage = 0;

  columnData: ColumnData<OrderItem>[] = [
    {
      key: 'id',
      title: 'Order ID',
      type: 'number',
    },
    {
      title: 'SKU Name',
      type: 'deep-string',
      key: 'productSku.name',
    },
    {
      title: 'SKU ID',
      type: 'deep-string',
      key: 'productSku.sku',
    },
    {
      key: 'quantity',
      title: 'Quantity',
      type: 'number',
    },
  ];

  constructor(public readonly ovAutoService: OvAutoService, public readonly activeModal: NgbActiveModal) {
    this.metadata = getFieldMetadata<OrderItem>(this.formClass);
  }

  ngOnInit() {
    this.onPaginate();
  }

  onCancel() {
    this.activeModal.close();
  }

  onPaginate() {
    const begin = this.currentPage * this.pageSize;
    const end = begin + this.pageSize;
    this.pageData = this.item.orderItems.slice(begin, end);
  }

  onPageChange(page) {
    this.currentPage = page;
    this.onPaginate();
  }

  onPageSizeChange(size) {
    this.pageSize = size;
    this.onPaginate();
  }
}
