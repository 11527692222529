import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { OvAutoService } from '@ov-suite/services';
import { LoadAllocation } from '@ov-suite/models-warehouse';
import { FieldMetadata, getFieldMetadata } from '@ov-suite/ov-metadata';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'ov-suite-load-allocation-print',
  templateUrl: './load-allocation-print.component.html',
  styleUrls: ['./load-allocation-print.component.scss'],
})
export class LoadAllocationPrintComponent implements OnInit {
  @ViewChild('report', { static: false }) content: ElementRef;

  loadAllocation: LoadAllocation = null;

  metadata: FieldMetadata<LoadAllocation>;

  constructor(public ovAutoService: OvAutoService, private readonly activatedRoute: ActivatedRoute) {}

  ngOnInit(): void {
    const loadId = Number(this.activatedRoute.snapshot.params.id);
    this.metadata = getFieldMetadata(LoadAllocation);

    this.ovAutoService
      .get({
        id: loadId,
        keys: [
          'id',
          'date',
          'externalVehicle.registration',
          'vehicle.registration',
          'loadBay.name',
          'orders.id',
          'orders.customer.name',
          'orders.customer.customerCode',
          'orders.orderItems.id',
          'orders.orderItems.quantity',
          'orders.orderItems.productSku.name',
          'orders.orderItems.productSku.sku',
          'orders.orderItems.productSku.description',
        ],
        entity: LoadAllocation,
      })
      .then(res => {
        this.loadAllocation = res;
        this.loadAllocation.loadOrders = [...this.loadAllocation.loadOrders].reverse();
      });
  }

  print() {
    window.print();
  }
}
