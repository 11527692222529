import { NgModule } from '@angular/core';
import { OvGenericModule } from '@ov-suite/ui';
import { ResourceExceptionModel } from '@ov-suite/models-warehouse';

@NgModule({
  imports: [
    OvGenericModule.forRoot({
      entity: ResourceExceptionModel,
      api: 'warehouselink',
      createTitle: 'Exception',
      route: ['exceptions'],
      tableTitle: 'Exception',
      hideAdd: true,
      hideImport: true,
      hideEditDelete: true,
      defaultOrderColumn: 'createDate',
      defaultOrderDirection: 'DESC',
    }),
  ],
})
export class ResourceExceptionModule {}
