import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { OvAutoService } from '@ov-suite/services';
import { InventoryContainer } from '@ov-suite/models-warehouse';
import { ColumnData, environment } from '@ov-suite/helpers-shared';
import { NavigationExtras, Router } from '@angular/router';
import { InventoryLocationCountModel } from '../models/inventory-location-count.model';

@Component({
  selector: 'ov-suite-pallet-modal',
  templateUrl: './pallet-modal.component.html',
})
export class PalletModalComponent implements OnInit {
  page = 0;

  pageSize = 10;

  @Input() model: InventoryLocationCountModel;

  filter: Record<string, number[]>;

  // Class - Required
  formClass = InventoryContainer;

  columns: ColumnData<InventoryContainer>[] = [
    {
      key: 'name',
      title: 'Container Name',
      type: 'string',
    },
    {
      title: 'Inventory',
      type: 'other',
      action: ic =>
        ic.inventories
          .map(i => i.productSku?.name)
          .filter(i => !!i)
          .join(', '),
      disableSorting: true,
      keys: ['inventories', 'inventories.productSku', 'inventories.productSku.name'],
    },
    {
      title: 'Inventory Count',
      type: 'other',
      disableSorting: true,
      action: ic => ic.inventories?.reduce((p, c) => p + c.quantity, 0).toString(),
      keys: ['inventories', 'inventories.quantity'],
    },
    // {
    //   title: 'Container Type',
    //   type: 'other',
    //   keys: ['type', 'type.name'],
    //   action: inventoryContainer => inventoryContainer.type?.name,
    //   disableSorting: true,
    //   disableFiltering: true,
    // },
    {
      type: 'buttons',
      title: 'Fast Actions',
      buttons: [
        {
          tooltip: 'Edit',
          classes: 'btn-primary btn-sm fa pt-1 pb-1 fa-pencil',
          action: (item: InventoryContainer) => {
            this.edit(item);
          },
        },
      ],
      keys: [],
      disableFiltering: true,
      disableSorting: true,
      id: 'fast_actions',
    },
  ];

  constructor(public autoService: OvAutoService, protected modalService: NgbModal, private readonly router: Router) {}

  ngOnInit(): void {
    this.filter = { 'inventoryLocation.id': [this.model?.inventoryLocation?.id] };
  }

  changePage(page: number): void {
    this.page = page;
  }

  close = () => {
    this.modalService.dismissAll();
  };

  edit(item: InventoryContainer) {
    const options: NavigationExtras = {
      queryParams: { id: item.id },
    };
    if (this.page) {
      // eslint-disable-next-line no-underscore-dangle
      options.queryParams._prevPage = this.page;
    }
    const url = this.router.serializeUrl(this.router.createUrlTree(['inventory-container', 'edit'], options));
    window.open(url, '_blank');
  }
}
