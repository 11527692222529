import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { LoadOrderItemModel } from '@ov-suite/models-warehouse';
import { LoadAllocationActionService } from '../load-allocation.action.service';
import { LoadAllocationDataService } from '../load-allocation.data.service';
import { OrderAllocation, SplitParam } from '../classes/order-allocation.class';

@Component({
  selector: 'ov-suite-load-split',
  templateUrl: './split.component.html',
  styleUrls: ['./split.component.scss'],
})
export class SplitComponent implements OnInit {
  splitParams: (SplitParam & { max: number })[] = [];

  order: OrderAllocation;

  moveTarget?: OrderAllocation;

  name: string;

  constructor(
    public readonly activeModal: NgbActiveModal,
    public data: LoadAllocationDataService,
    public action: LoadAllocationActionService,
  ) {}

  ngOnInit(): void {}

  setOrderItemAllocations(items: LoadOrderItemModel[]) {
    this.splitParams = items.map(item => ({
      orderItem: item.orderItem,
      quantity: item.quantity,
      max: item.quantity,
    }));
  }

  removeItem(item: SplitParam & { max: number }, index: number) {
    if (this.splitParams.length > 1) this.splitParams.splice(index, 1);
  }

  setOrderAllocation(order: OrderAllocation) {
    this.order = order;
  }

  setMoveTarget(order: OrderAllocation) {
    this.moveTarget = order;
  }

  async move() {
    if (this.moveTarget) {
      this.order.moveItemsTo(this.moveTarget, this.splitParams);
      await this.data.saveLoadOrder(this.moveTarget.loadOrder, this.order.loadOrder);
    }
    this.activeModal.close();
  }

  async split() {
    if (this.action.orderSelected.value) {
      await this.data.splitOrder(this.action.orderSelected.value, this.splitParams, this.name);
      this.action.clearOrder();
      this.splitParams = [];
    }
    this.activeModal.close();
  }

  close() {
    this.activeModal.close();
  }
}
