import { Component, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FieldMetadata, GenericHierarchy, getFieldMetadata } from '@ov-suite/ov-metadata';
import { OvAutoService } from '@ov-suite/services';
import { FormComponent } from '@ov-suite/ui';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { getCreate, getUpdate, parseMutationDomains } from '@ov-suite/graphql-helpers';
import { WaveConfig, WaveInstance } from '@ov-suite/models-warehouse';
import { WaveService } from '../wave.service';
import { Domain } from '@ov-suite/models-admin';
import { DomainService } from '@ov-suite/helpers-angular';

interface DataSources<T extends GenericHierarchy = { id: number | string }> {
  [key: string]: T[] | OvAutoService | unknown;
}

@Component({
  selector: 'ov-suite-wave-add-or-edit',
  templateUrl: './wave-add-or-edit.component.html',
  styleUrls: ['./wave-add-or-edit.component.scss'],
})
export class WaveAddOrEditComponent implements OnInit {
  formClass = WaveConfig;

  metadata: FieldMetadata<WaveConfig>;

  isCloned: boolean;

  requiredFields: Record<string, boolean>;

  selectedTemplate = 0;

  selectedDomains: Domain[] = [];

  saveAsTemplate: boolean;

  templateName: string;

  closeResult = '';

  output: WaveConfig;

  affected: WaveInstance[];

  private _data: WaveConfig = new WaveConfig();

  original: WaveConfig;

  set data(item: WaveConfig) {
    this.original = { ...item };
    this._data = item;
  }

  get data() {
    return this._data;
  }

  @Input() public dataSources: DataSources<GenericHierarchy> = {};

  @ViewChild('form') form: FormComponent;

  loadMap: Record<string, unknown> = {};

  constructor(
    public readonly waveService: WaveService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    public readonly ovAutoService: OvAutoService,
    private readonly modalService: NgbModal,
    private readonly domainService: DomainService,
    @Inject('DEFAULT_API') private readonly defaultApi: string,
  ) {
    this.route.url.subscribe(segments => {
      const [segment] = segments;
      if (segment.path === 'clone') {
        // remove id
        this.isCloned = true;
      }
    });
  }

  ngOnInit() {
    this.metadata = getFieldMetadata(this.formClass);

    this.route.queryParamMap.subscribe(response => {
      if (response.has('id')) {
        const id = Number(response.get('id'));
        this.ovAutoService.get(this.formClass, this.metadata.api, id).then(item => {
          this.data = item;
          this.selectedDomains = [...item.domains];
        });
      } else {
        this.data = new this.formClass();
        this.data.domains = [this.domainService.getCurrentDomain()];
        this.selectedDomains = this.data.domains;
      }
    });
  }

  public onCancel(): void {
    window.history.back();
  }

  onSave(content) {
    if (this.templateName) {
      this.output.templateName = this.templateName;
    }

    if (this.output.id) {
      this.waveService
        .updateWaveConfig(parseMutationDomains(this.data, getUpdate(this.output, this.original)))
        .then(res => {
          this.affected = res.changes;

          this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then(
            result => {
              this.closeResult = `Closed with: ${result}`;
              const output = parseMutationDomains(this.data, getUpdate(this.output, this.original));
              output['commit'] = true;
              this.waveService.updateWaveConfig(output).then(response => {
                this.router.navigate(['/waves']);
                this.modalService.dismissAll();
              });
            },
            reason => {
              this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
            },
          );
        })
        .catch(err => {
          console.error(err);
        });
    } else {
      this.ovAutoService.create(this.formClass, this.metadata.api, parseMutationDomains(this.data, getCreate(this.output))).then(res => {
        this.modalService.dismissAll();
        this.router.navigate(['/waves']);
      });
    }
  }

  public async onNext(content) {
    const output = this.data ?? new this.formClass();

    let requiredFlag = false;

    // validate required fields
    this.metadata.fields.forEach(field => {
      const propKey = field.propertyKey;

      if (Object.keys(output).find(key => key === propKey)) {
        if (field.required && !output[propKey]) {
          this.requiredFields = { [propKey]: true, ...this.requiredFields };
          requiredFlag = true;
        } else {
          this.requiredFields = { [propKey]: false, ...this.requiredFields };
        }
      }
    });

    if (!requiredFlag) {
      if (this.isCloned) {
        delete output.id;
        delete output.createdAt;
      }

      this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then(
        result => {
          this.closeResult = `Closed with: ${result}`;
        },
        reason => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        },
      );

      this.output = output;
    }
  }

  setSelectedDomains(input: Domain[]): void {
    this.data.domains = [...input];
  }

  getDismissReason(reason: unknown): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    }
    if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    }
    return `with: ${reason}`;
  }
}
