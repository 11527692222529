import { LoadAllocation } from '@ov-suite/models-warehouse';
import { EventEmitter } from '@angular/core';
import { OrderAllocation } from './classes/order-allocation.class';

/**
 * Class that represents a vehicle on the lower left corner of the screen
 */
export class VehicleAllocation {
  load: LoadAllocation;

  weightLimit?: number;

  volumeLimit?: number;

  dirty = false;

  slots: Slot[];

  static fromLoadAllocation(load: LoadAllocation): VehicleAllocation {
    const slot = Slot.fromLoadAllocation(load);

    return Object.assign(new VehicleAllocation(), {
      load,
      weightLimit: round(load.getVehicle()?.class?.weightLoadAllowed),
      volumeLimit: round(
        (load.getVehicle()?.class?.length / 100) * (load.getVehicle()?.class?.width / 100) * (load.getVehicle()?.class?.height / 100),
      ),
      slots: slot ? [slot] : [],
    } as Partial<VehicleAllocation>);
  }

  commit(): void {
    this.load.loadOrders = [];
    const pins = [];
    this.slots.forEach(slot => {
      slot.pins.forEach(pin => {
        pins.push(pin);
        const { weight, volume, ...allocation } = pin;

        this.load.loadOrders.push(allocation.loadOrder);
      });
    });
  }

  removePin(input: OrderAllocation): void {
    this.slots.forEach(slot => {
      slot.pins = slot.pins.filter(pin => pin !== input);
    });
  }
}

export class Slot {
  id: number;

  startTime: string;

  endTime: string;

  pins: OrderAllocation[] = [];

  static fromLoadAllocation(load: LoadAllocation): Slot {
    if (load.vehicleTemplate) {
      const templateLine = load.vehicleTemplate.vehicleLines.find(t => t.vehicleId === load.vehicle?.id);
      if (templateLine) {
        return Object.assign(new Slot(), {
          startTime: templateLine.startTime,
          endTime: templateLine.endTime,
          pins: load.loadOrders.map(o => OrderAllocation.fromLoadOrder(o)),
        });
      }
      if (!load.loadOrders.length) {
        return null;
      }
    }

    return Object.assign(new Slot(), {
      startTime: load.getVehicle().resource?.startTime,
      endTime: load.getVehicle().resource?.endTime,
      pins: load.loadOrders.map(o => OrderAllocation.fromLoadOrder(o)),
    });
  }
}

// export class OrderAllocation {
//   id: number | string;
//
//   order: Order;
//
//   orderItems: OrderItem[];
//
//   weight: number;
//
//   volume: number;
//
//   allocated: boolean = false;
//
//   subOrders: OrderAllocationPart[] = [];
//
//   link?: OrderAllocation;
//
//   part = 0;
//
//   constructor() {}
//
//   get fullyAllocated(): boolean {
//     return this.allocated && this.subOrders.every(a => a.allocated);
//   }
//
//   // /**
//   //  * Sub orders only
//   //  */
//   // addOrderItem(orderItem: OrderItem): void {
//   //   if (!this.link) {
//   //     throw new Error('Root OrderAllocations are not mutable');
//   //   }
//   //   this.orderItems.push(orderItem);
//   // }
//   // /**
//   //  * Sub orders only
//   //  */
//   // removeOrderItem(orderItem: OrderItem): void {
//   //   if (!this.link) {
//   //     throw new Error('Root OrderAllocations are not mutable');
//   //   }
//   //   this.orderItems = this.orderItems.filter(i => i !== orderItem);
//   // }
//   // /**
//   //  * Sub orders only
//   //  */
//   // toggleOrderItem(orderItem: OrderItem): void {
//   //   if (!this.link) {
//   //     throw new Error('Root OrderAllocations are not mutable');
//   //   }
//   //   if (this.orderItems.includes(orderItem)) {
//   //     this.removeOrderItem(orderItem);
//   //   } else {
//   //     this.addOrderItem(orderItem);
//   //   }
//   // }
//
//   /**
//    * Splits OrderAllocation into to 2 OrderAllocations, used for Order splitting and Backlogs
//    */
//   splitOrder(orderItems: OrderItem[], remainderItems: OrderItem[] = []): [OrderAllocation, OrderAllocation] {
//     const orderPart1 = Object.assign(new OrderAllocationPart(), {
//       id: v4(),
//       order: this.order,
//       orderItems: this.orderItems.filter(item => !orderItems.includes(item)),
//       weight: 0,
//       volume: 0,
//       link: this,
//       part: 1,
//     });
//     const orderPart2 = Object.assign(new OrderAllocationPart(), {
//       id: v4(),
//       order: this.order,
//       orderItems,
//       weight: 0,
//       volume: 0,
//       link: this,
//       part: 2,
//     });
//     orderPart1.computeMetrics();
//     orderPart2.computeMetrics();
//     this.subOrders.push(orderPart1, orderPart2);
//     return [orderPart1, orderPart2];
//   }
//
//   // /**
//   //  * Splits an OrderItem into 2 order items, used for Order Splitting and Backlogs
//   //  */
//   // splitOrderItem(orderItem: OrderItem, count: number): void {
//   //   if (!this.orderItems.includes(orderItem)) {
//   //     throw new Error('Invalid Split, Order Item does not exist on this order!');
//   //   }
//   //   if (count >= orderItem.quantity) {
//   //     throw new Error('Invalid Split, Split amount exceeds order item quantity');
//   //   }
//   //   const newOrderItem = Object.assign(new OrderItem(), {
//   //     quantity: count,
//   //     productSku: orderItem.productSku
//   //   })
//   //   const insertIndex = this.orderItems.indexOf(orderItem);
//   //   this.orderItems = [
//   //     ...this.orderItems.slice(0, insertIndex),
//   //     newOrderItem,
//   //     ...this.orderItems.slice(insertIndex),
//   //   ];
//   //   orderItem.quantity -= count;
//   // }
//
//   // /**
//   //  * Resets current Order Allocation back to its original link
//   //  */
//   // resetOrder(): void {
//   //   if (!this.link) {
//   //     throw new Error('Invalid Reset, this order has no link!')
//   //   }
//   //   this.link.mergeOrder(this);
//   // }
//
//   // /**
//   //  * Resets targeted Order Allocation back into the current Order Allocation
//   //  * @param orderAllocation
//   //  */
//   // mergeOrder(orderAllocation: OrderAllocation): void {
//   //   if (orderAllocation.order !== this.order) {
//   //     throw new Error('Invalid Merge, Orders do not match!');
//   //   }
//   //   this.orderItems = [...this.orderItems, ...orderAllocation.orderItems];
//   //   this.computeMetrics();
//   // }
//
//   /**
//    * Recalculates the current Weight and Volume
//    */
//   computeMetrics(): void {
//     this.weight = getOrderItemsWeight(this.orderItems);
//     this.volume = getOrderItemsVolume(this.orderItems);
//   }
//
//   setAllocation(value: boolean): void {
//     this.allocated = value;
//   }
//
//   static fromOrder(order: Order): OrderAllocation {
//     return Object.assign(new OrderAllocation(), {
//       order,
//       orderItems: order.orderItems,
//       weight: getOrderItemsWeight(order.orderItems),
//       volume: getOrderItemsVolume(order.orderItems)
//     })
//   }
//
//   static fromOrderItems(orderItems: OrderItem[], order: Order): OrderAllocation {
//     return Object.assign(new OrderAllocation(), {
//       order,
//       orderItems: orderItems,
//       weight: getOrderItemsWeight(orderItems),
//       volume: getOrderItemsVolume(orderItems)
//     })
//   }
// }

// export class OrderAllocationPart extends OrderAllocation {
//   link: OrderAllocation;
//
//   // part: number;
// }

// function getOrderItemsWeight(orderItems: OrderItem[]): number {
//   return round(orderItems.reduce((p, c) => p + c.productSku?.weight * c.quantity, 0) / 1000);
// }
//
// function getOrderItemsVolume(orderItems: OrderItem[]): number {
//   return round(
//     orderItems.reduce(
//       (p, c) => p + (c.productSku?.width / 100) * (c.productSku?.length / 100) * (c.productSku?.height / 100) * c.quantity,
//       0,
//     ),
//   );
// }

function round(amount: number, decimal: number = 2): number {
  return Math.round(amount * 10 ** decimal) / 10 ** decimal;
}

export interface TimeSlotHeader {
  name: string;
  value: number;
  left: number;
}

export interface TimeSlot {
  width: number;
  hourSpacing: number;
  totalWidth: number;
  startTime: number;
  endTime: number;
  headings: TimeSlotHeader[];
}

export interface SingleCombo<T> {
  value: T;
  observable: EventEmitter<T>;
}

export interface ListCombo<T> {
  value: T[];
  map?: Record<number, T>;
  all?: T[];
  observable: EventEmitter<T[]>;
}
