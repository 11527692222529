import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { UiModule } from '@ov-suite/ui';
import { RouterModule } from '@angular/router';
import { TableTopTabMenuComponent } from './table-top-tab-menu.component';

@NgModule({
  imports: [CommonModule, FormsModule, UiModule, RouterModule],
  declarations: [TableTopTabMenuComponent],
  exports: [TableTopTabMenuComponent],
  entryComponents: [TableTopTabMenuComponent],
})
export class TableTopTabMenuModule {}
