import { NgModule } from '@angular/core';
import { OvGenericModule, UiModule } from '@ov-suite/ui';
import { WarehousePermission } from '../../helpers/permissions.helpers';
import { Order } from '@ov-suite/models-order';
import { OrderDetailsPopupComponent } from './order-details-popup/order-details-popup.component';
import { CommonModule } from '@angular/common';

@NgModule({
  imports: [
    CommonModule,
    UiModule,
    OvGenericModule.forRoot({
      entity: Order,
      api: 'warehouselink',
      createTitle: 'Order',
      route: ['orders'],
      tableTitle: 'Order',
      hideAdd: true,
      hideImport: true,
      hideEditDelete: true,
      hideColumns: ['completedDate'],
      permissionId: WarehousePermission.inventoryList,
      defaultOrderDirection: 'DESC',
      defaultOrderColumn: 'orderDate',
      onClick: (item, ngModal) => {
        const modalRef = ngModal.open(OrderDetailsPopupComponent, { size: 'xl' });
        modalRef.componentInstance.item = item;
      },
      tabs: [
        {
          path: '/orders',
          title: 'Orders',
        },
        {
          path: '/completed-orders',
          title: 'Completed Orders',
        },
      ],
    }),
  ],
  declarations: [OrderDetailsPopupComponent],
})
export class OrdersModule {}
