import { NgModule } from '@angular/core';
import { OvGenericModule, UiModule } from '@ov-suite/ui';
import { WarehousePermission } from '../../helpers/permissions.helpers';
import { Order } from '@ov-suite/models-order';
import { CommonModule } from '@angular/common';

@NgModule({
  imports: [
    CommonModule,
    UiModule,
    OvGenericModule.forRoot({
      entity: Order,
      api: 'warehouselink',
      createTitle: 'Completed Orders',
      route: ['completed-orders'],
      tableTitle: 'Completed Orders',
      hideAdd: true,
      hideImport: true,
      hideEditDelete: true,
      permissionId: WarehousePermission.inventoryList,
      defaultOrderDirection: 'DESC',
      defaultOrderColumn: 'orderDate',
      defaultFilter: { completedDate: [{ operator: 'IS NOT', value: null }] },
      tabs: [
        {
          path: '/orders',
          title: 'Orders',
        },
        {
          path: '/completed-orders',
          title: 'Completed Orders',
        },
      ],
    }),
  ],
  declarations: [],
})
export class CompletedOrdersModule {}
