import { Routes } from '@angular/router';
import { AuthGuard } from '@ov-suite/authguard-angular';
import { InventoryLocationListComponent } from './list/inventory-location-list.component';
import { WarehousePermission } from '../../helpers/permissions.helpers';

export const InventoryProductSkuRoutes: Routes = [
  {
    path: '',
    children: [
      {
        data: { feature: { id: WarehousePermission.inventoryProduct } },
        canActivate: [AuthGuard],
        path: '',
        component: InventoryLocationListComponent,
      },
    ],
  },
];
