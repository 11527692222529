import { Apollo } from 'apollo-angular';
import { Injectable } from '@angular/core';
import gql from 'graphql-tag';
import { FlowablePage, ProcessDefinition } from '@ov-suite/models-warehouse';

@Injectable()
export class ProcessDefinitionService {
  constructor(private readonly apollo: Apollo) {}

  async listProcessDefinitions(params: Object): Promise<FlowablePage<ProcessDefinition>> {
    const query = gql(`
      query ListProcessDefinitions($params: ListProcessDefinitionInput!) {
        listProcessDefinitions(query: $params) {
           data {
             id
             url
             version
             key
             category
             suspended
             name
             description
             deploymentId
             deploymentUrl
             graphicalNotationDefined
             resource
             diagramResource
             startFormDefined
           }
              total
              start
              sort
              order
              size
        }
      }
    `);

    return new Promise((resolve, reject) => {
      this.apollo
        .use('warehouselink')
        .query({
          query,
          variables: { params },
          fetchPolicy: 'no-cache',
        })
        .subscribe(response => {
          const rawData = response.data['listProcessDefinitions'];
          resolve(rawData);
        }, reject);
    });
  }

  async getImage(url: string): Promise<string> {
    const query = gql(`
      query GetImage($url: String!) {
        getImage(url: $url)
      }
    `);

    return new Promise((resolve, reject) => {
      this.apollo
        .use('warehouselink')
        .query({
          query,
          variables: { url },
          fetchPolicy: 'no-cache',
        })
        .subscribe(response => {
          const data = response.data['getImage'];
          resolve(data);
        }, reject);
    });
  }
}
