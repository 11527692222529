import { Component, OnInit } from '@angular/core';
import moment from 'moment/moment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LoadOrderItemModel } from '@ov-suite/models-warehouse';
import { LoadAllocationBaseService } from '../load-allocation.base.service';
import { LoadAllocationActionService } from '../load-allocation.action.service';
import { LoadAllocationDataService } from '../load-allocation.data.service';
import { SplitComponent } from '../split/split.component';

@Component({
  selector: 'ov-suite-load-allocation-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.scss'],
})
export class DetailComponent implements OnInit {
  active = 1;

  orderPriority: Record<number, { text: string; color: string }> = {
    1: { text: 'Critical', color: 'badge-danger' },
    2: { text: 'High', color: 'badge-orange' },
    3: { text: 'Medium', color: 'badge-warning' },
    4: { text: 'Low', color: 'badge-success' },
  };

  splitItems: LoadOrderItemModel[] = [];

  constructor(
    public readonly base: LoadAllocationBaseService,
    public readonly action: LoadAllocationActionService,
    public readonly data: LoadAllocationDataService,
    private readonly modalService: NgbModal,
  ) {}

  ngOnInit(): void {}

  getSLA(orderDate: string, fulfilDate: string): string {
    console.log({ orderDate, fulfilDate });
    return `${moment(fulfilDate).diff(moment(orderDate), 'days')} days`;
  }

  onCheck(orderItem: LoadOrderItemModel) {
    if (this.splitItems.includes(orderItem)) {
      this.splitItems = this.splitItems.filter(i => i !== orderItem);
    } else {
      this.splitItems.push(orderItem);
    }
  }

  splitOrder(): void {
    const ref = this.modalService.open(SplitComponent, { size: 'lg' });
    ref.componentInstance.setOrderItemAllocations(this.splitItems);
  }

  moveOrder(): void {
    const ref = this.modalService.open(SplitComponent, { size: 'lg' });
    ref.componentInstance.setOrderItemAllocations(this.splitItems);
    ref.componentInstance.setOrderAllocation(this.action.orderSelected.value);
    ref.componentInstance.setMoveTarget(this.action.selectedOrderPart);
  }
}
