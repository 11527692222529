import { Injectable, EventEmitter } from '@angular/core';
import { CdkDragDrop, CdkDragStart, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { LoadAllocation, WaveInstance } from '@ov-suite/models-warehouse';
import { InventoryLocation } from '@ov-suite/models-admin';
import { WaveAllocationDataService } from './wave-allocation.data.service';
import { LoadPlan } from './wave-allocation.interface';
import { SingleCombo } from '../load-allocation/load-allocation.interface';

/**
 * This service is used to manage interaction between panels on load-allocation.
 *
 * This typically means user actions like hovering and selecting
 */

@Injectable()
export class WaveAllocationActionService {
  vehicleConflicts: Record<number, number[]> = {};

  conflictCheck = new EventEmitter<LoadPlan>();

  loadSelected: SingleCombo<LoadAllocation> = {
    value: null,
    observable: new EventEmitter<LoadAllocation>(),
  };

  constructor(private readonly data: WaveAllocationDataService) {}

  selectLoad(load: LoadAllocation) {
    this.loadSelected.value = load;
    this.loadSelected.observable.emit(load);
  }

  drop(event: CdkDragDrop<LoadPlan[]>, wave?: WaveInstance, loadBay?: InventoryLocation): void {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(event.previousContainer.data, event.container.data, event.previousIndex, event.currentIndex);
    }
    event.item.data.wave = wave;
    event.item.data.loadBay = loadBay;
    this.cleanConflict(event.item.data);
    this.checkConflicts(event.item.data);

    this.data.save(event.item.data);
  }

  cleanConflict(plan: LoadPlan): void {
    if (this.vehicleConflicts[plan.load.id]) {
      this.vehicleConflicts[plan.load.id].forEach(conflictId => {
        this.vehicleConflicts[conflictId] = this.vehicleConflicts[conflictId].filter(i => i !== plan.load.id);
        if (!this.vehicleConflicts[conflictId].length) {
          delete this.vehicleConflicts[conflictId];
        }
      });
      delete this.vehicleConflicts[plan.load.id];
    }
  }

  logConflict(plans: LoadPlan[]): void {
    plans.forEach(plan => {
      this.vehicleConflicts[plan.load.id] = [
        ...(this.vehicleConflicts[plan.load.id] ?? []),
        ...plans.filter(p => p !== plan).map(i => i.load.id),
      ];
    });
  }

  checkConflicts(plan: LoadPlan): void {
    this.conflictCheck.emit(plan);
  }
}
