import { Component, NgZone, OnDestroy, OnInit } from '@angular/core';
import {
  control,
  latLngBounds,
  LatLngBounds,
  MapOptions,
  tileLayer,
  Map,
  CircleMarker,
  circleMarker,
  LatLng,
  polyline,
  Polyline,
} from 'leaflet';
import { Memo } from '@ov-suite/helpers-shared';
import { LoadAllocationBaseService } from '../load-allocation.base.service';
import { VehicleAllocation } from '../load-allocation.interface';
import { LoadAllocationActionService } from '../load-allocation.action.service';
import { LoadAllocationDataService } from '../load-allocation.data.service';
import { OrderAllocation } from '../classes/order-allocation.class';

const styles = {
  factorySize: 15,
  orderSize: 10,
  highlightedCustomerSize: 13,

  displayOpacity: 1,
  hiddenOpacity: 0.1,
  hoverOpacity: 0.2,

  sourceFactoryColor: '#50E3C2',
  destinationFactoryColor: '#5F59F7',
  unusedFactoryColor: 'grey',
  highlightedCustomerColor: '#bf73de',
  selectedCustomerColor: '#FCD861',
  unselectedCustomerColor: '#A9E5FF',
  noRouteCustomerColor: '#FC5153',
  greyButtonColor: '#888888',
};

@Component({
  selector: 'ov-suite-load-allocation-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
})
export class MapComponent implements OnInit, OnDestroy {
  map: Map;

  bounds: LatLngBounds = latLngBounds([
    [-29.65881937, 29.780789],
    [-28.5596713, 30.376746976],
  ]);

  options: MapOptions = {
    layers: [tileLayer('http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', { maxZoom: 18, attribution: '...', minZoom: 4 })],
    // center: latLng(0, 0),
    // zoom: 2,
    zoomControl: false,
    doubleClickZoom: false,
  };

  pinState = {
    selected: {},
    highlighted: {},
  };

  orderPinsArray: CircleMarker[] = [];

  orderPinsMap: Record<number, CircleMarker> = {};

  previousSelectedOrder?: CircleMarker;

  lines: Polyline[] = [];

  constructor(
    private readonly ngZone: NgZone,
    public base: LoadAllocationBaseService,
    public data: LoadAllocationDataService,
    public action: LoadAllocationActionService,
  ) {}

  ngOnInit(): void {
    this.subscribeToOrders();
    this.subscribeToVehicleSelection();
    this.subscribeToOrderSelection();
    this.subscribeToOrderHovers();
  }

  ngOnDestroy(): void {
    this.action.orderSelected.observable.unsubscribe();
    this.action.vehicleSelected.observable.unsubscribe();
    this.action.orderSelected.observable.unsubscribe();
    this.action.orderHoverStart.unsubscribe();
    this.action.orderHoverEnd.unsubscribe();
  }

  subscribeToOrders(): void {
    this.data.orderAllocations.observable.subscribe(() => {
      if (this.data.orderAllocations.all) {
        const map: Record<number, CircleMarker> = {};
        this.data.orderAllocations.all.forEach(allocation => {
          const pin = this.getPin(allocation);
          if (pin) {
            map[allocation.loadOrder.id] = pin;
          }
        });
        this.orderPinsMap = map;
        this.orderPinsArray = Object.values(map);
        this.updateBounds();
      }
    });
  }

  subscribeToVehicleSelection(): void {
    this.action.vehicleSelected.observable.subscribe((allocation: VehicleAllocation) => {
      if (allocation) {
        const lines: Polyline[] = [];
        const coords: LatLng[] = [];

        allocation.slots.forEach(slot => {
          slot.pins.forEach(pin => {
            const { loadOrder } = pin;
            const [longitude, latitude] = loadOrder?.order?.deliveryAddress?.geography?.coordinates ?? [0, 0];
            coords.push(new LatLng(latitude, longitude));
          });
        });

        coords.forEach((set, index) => {
          if (index < coords.length - 1) {
            lines.push(polyline([set, coords[index + 1]], { color: '#0091FF', opacity: 0.6 }));
          }
        });

        if (coords.length) {
          this.bounds = new LatLngBounds(coords.map(c => [c.lat, c.lng]));
        }

        setTimeout(() => lines.forEach(i => i.bringToBack()), 1);

        this.lines = lines;
      } else {
        this.lines = [];
      }
    });
  }

  subscribeToOrderSelection(): void {
    this.action.orderSelected.observable.subscribe((allocation: OrderAllocation) => {
      if (this.previousSelectedOrder) {
        this.previousSelectedOrder.setStyle({
          fillColor: styles.unselectedCustomerColor,
        });
      }
      if (allocation && this.orderPinsMap[allocation.loadOrder.id]) {
        const pin = this.orderPinsMap[allocation.loadOrder.id];
        pin
          .setStyle({
            fillColor: styles.selectedCustomerColor,
          })
          .bringToFront();
        this.previousSelectedOrder = pin;
      }
    });
  }

  subscribeToOrderHovers(): void {
    this.action.orderHoverStart.subscribe((allocation: OrderAllocation) => {
      if (allocation && this.orderPinsMap[allocation.loadOrder.id]) {
        this.orderPinsMap[allocation.loadOrder.id]
          .setStyle({
            className: 'pin-hover',
            weight: 5,
          })
          .setRadius(styles.highlightedCustomerSize);
      }
    });

    this.action.orderHoverEnd.subscribe((allocation: OrderAllocation) => {
      if (allocation && this.orderPinsMap[allocation.loadOrder.id]) {
        this.orderPinsMap[allocation.loadOrder.id]
          .setStyle({
            weight: 1,
          })
          .setRadius(styles.orderSize);
      }
    });
  }

  @Memo()
  getPins(allocations: OrderAllocation[]): CircleMarker[] {
    return allocations.map(allocation => this.getPin(allocation)).filter(a => !!a);
  }

  getPin(allocation: OrderAllocation): CircleMarker {
    const { customer } = allocation.loadOrder.order;
    const [longitude, latitude] = allocation.loadOrder.order?.deliveryAddress?.geography?.coordinates ?? [null, null];
    // const { latitude, longitude } = map ?? customer?.map ?? {};
    if (latitude == null && longitude == null) {
      return null;
    }
    return circleMarker([latitude, longitude], {
      color: 'black',
      fillColor: styles.unselectedCustomerColor,
      fillOpacity: styles.displayOpacity,
      opacity: 0.2,
      radius: styles.orderSize,
      weight: 1,
    })
      .bindTooltip(customer.name)
      .on('click', () => {
        this.ngZone.run(() => {
          this.action.selectOrder(allocation);
        });
      });
  }

  updateBounds(): void {
    if (this.orderPinsArray.length) {
      const allPins = this.orderPinsArray.map(p => {
        const { lat, lng } = p.getLatLng();
        return [lat, lng] as [number, number];
      });
      this.bounds = new LatLngBounds(allPins);
    } else {
      this.bounds = latLngBounds([
        [5, 5],
        [-5, -5],
      ]);
    }
  }

  public updateMap(): void {
    this.map.invalidateSize();
  }

  onMapReady(map: Map): void {
    this.map = map;
    map.addControl(control.zoom({ position: 'bottomright' }));
  }
}
